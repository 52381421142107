export const TOOLBAR_WIDTH = 50;
export const SIDEBAR_WIDTH = 300;
export const FOOTER_HEIGHT = 20;

export enum SIDEBAR_ITEMS {
  'token_list' = 'Token List',
  'create_token' = 'Create Token',
  'boot_buy_tx' = 'Boot Buy/Sell TX',
  'boot_volume_tx' = 'Boot Volume TX',
  'pump_boot_buy_tx' = 'Pump Boot Buy/Sell TX',
  'Pump_boot_volume_tx' = 'Pump Boot Volume TX',
  'create_pumpfun_token' = 'Create Pumpfun token',
  'pumpfun_trader' = 'Trader',
  'pumpfun_tokens' = 'Pumpfun tokens',
  'send_sol' = 'Send Sol',
  'gen_wallet' = 'Generate Wallet',
  'multi_sender' = 'MultiSender',
  'create_book' = 'Create Open Book',
  'create_pool' = 'Create Pool',
  'claim_sol' = 'claim Sol',
  'okx_withdraw' = 'OKX Withdraw',
  'holder' = 'Holder',
  'mexc_withdraw' = 'MEXC Withdraw',
  'gate_withdraw' = 'GateIO Withdraw',
  'binance_withdraw' = 'Binance Withdraw',
  'bybit_withdraw' = 'Bybit Withdraw',
  'bitget_withdraw' = 'Bitget Withdraw',
  'burn_token' = 'Burn token',
  'pumpfun_bundle' = 'Pumpfun Bundle',
}
