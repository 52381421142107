import React from 'react';

const BinanceIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM67.3231 61.1012L61.0967 54.8771L50.4748 65.5562L39.8506 54.8771L33.6242 61.1035L50.4725 77.9519L67.3231 61.1012ZM78 50.4771L71.7231 44.2529L65.5517 50.4771L71.7781 56.7012L78 50.4771ZM56.699 50.4221L50.4748 44.2529L50.4725 44.2552L44.2483 50.4771L50.4725 56.6485L56.699 50.4221ZM35.3979 50.4771L29.2242 44.2529L23 50.4771L29.2288 56.6462L35.3979 50.4771ZM67.3254 39.7979L50.4771 23L50.4748 23.0023L33.6265 39.8506L39.8529 46.0748L50.4748 35.3956L61.099 46.0198L67.3254 39.7979Z"
        fill="black"
      />
    </svg>
  );
};

export default BinanceIcon;
