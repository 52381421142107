export const OkxIcon = () => (
  <svg
    width="150px"
    height="150px"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#A43399"
      fillRule="evenodd"
      d="M13.81,13.57v123.98h123.98V13.57H13.81Zm26.82,28.64c0-.87,.71-1.58,1.58-1.58h20.3c.87,0,1.58,.71,1.58,1.58v20.3c0,.88-.71,1.59-1.58,1.59h-20.3c-.87,0-1.58-.71-1.58-1.59v-20.3Zm23.46,66.7c0,.87-.71,1.58-1.58,1.58h-20.3c-.87,0-1.58-.71-1.58-1.58v-20.3c0-.88,.71-1.59,1.58-1.59h20.3c.87,0,1.58,.71,1.58,1.59v20.3Zm21.86-21.62h-20.3c-.87,0-1.59-.71-1.59-1.59v-20.3c0-.87,.71-1.59,1.59-1.59h20.3c.87,0,1.59,.71,1.59,1.59v20.3c0,.87-.71,1.59-1.59,1.59Zm25.05,21.62c0,.87-.71,1.58-1.59,1.58h-20.3c-.87,0-1.58-.71-1.58-1.58v-20.3c0-.88,.71-1.59,1.58-1.59h20.3c.87,0,1.59,.71,1.59,1.59v20.3Zm0-46.41c0,.88-.71,1.59-1.59,1.59h-20.3c-.87,0-1.58-.71-1.58-1.59v-20.3c0-.87,.71-1.58,1.58-1.58h20.3c.87,0,1.59,.71,1.59,1.58v20.3Z"
    />
  </svg>
);
