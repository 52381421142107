export const BootTxIcon = () => (
  <svg
    fill="#000000"
    height="200px"
    width="200px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 511.996 511.996"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <path d="M389.826,121.758l-128-119.467c-3.285-3.055-8.363-3.055-11.648,0l-128,119.467c-1.724,1.613-2.714,3.874-2.714,6.238 v136.533c0,3.448,2.082,6.562,5.274,7.885c3.191,1.323,6.852,0.589,9.293-1.852l121.967-121.967l121.967,121.967 c1.638,1.63,3.823,2.5,6.033,2.5c1.101,0,2.21-0.213,3.268-0.648c3.192-1.323,5.265-4.437,5.265-7.885V127.996 C392.531,125.632,391.55,123.371,389.826,121.758z"></path>{' '}
            <path d="M261.826,241.225c-3.285-3.055-8.363-3.055-11.648,0l-128,119.467c-1.724,1.613-2.714,3.874-2.714,6.238v136.533 c0,3.447,2.082,6.562,5.274,7.885c3.191,1.314,6.852,0.597,9.293-1.852l121.967-121.967l121.967,121.967 c1.638,1.63,3.823,2.5,6.033,2.5c1.101,0,2.21-0.213,3.268-0.648c3.192-1.323,5.265-4.437,5.265-7.885V366.929 c0-2.364-0.981-4.625-2.705-6.238L261.826,241.225z"></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
);
