/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import CommonLoading from '@/components/common/CommonLoading';
import { Loader } from '@/components/common/loader';
import Input from '@/components/form/input';
import SliderItem from '@/components/form/slider';
import { InputContainer } from '@/components/form/styled';
import TextArea from '@/components/form/textarea';
import { Title } from '@/components/login';
import { Container, JumboTron } from '@/components/token/components';
import { Text } from '@/components/token/components/info';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import {
  checkIsRequesting,
  getAddressFromPrivateKey,
  setErrorWithTime,
  validateKey,
  validatePublicKey,
} from '@/utils/helper';
import { Alert, Box } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface WalletType {
  contractAddress: string;
  privateKey: string;
}
export const MultiSenderPage = () => {
  const [address, setAddress] = useState<string | undefined>('');
  const [validErrorValue, setValidErrorValue] = useState('');

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    requestResetToken,
    requestSendMultiToken,
    sendMultiTokenStatus,
    multiToken,
  } = useTokenHook();

  const loading = useMemo(
    () => checkIsRequesting([sendMultiTokenStatus]),
    [sendMultiTokenStatus],
  );

  useEffect(() => {
    if (sendMultiTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Multi token has been sent successfully!']);
    }

    if (sendMultiTokenStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to transfrer multi token.']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMultiTokenStatus]);

  const privateKeyRef = useRef<any>();
  const contractRef = useRef<any>();
  const walletRef = useRef<any>();
  const percentRef = useRef<any>();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors, isDirty },
  } = useForm<{
    contractAddress: string;
    wallets: string;
    percent: number;
    privateKey: string;
  }>({
    defaultValues: {},
  });

  const watchValuePrivateKey = watch('privateKey');
  const isValidKey = validateKey(watchValuePrivateKey);

  const submitForm = async (formDt: any) => {
    formDt.wallets = formDt.wallets?.split(/\r?\n/) ?? [];
    formDt.percent = +formDt.percent;
    requestSendMultiToken(formDt);
  };

  const formData = watch();

  useEffect(() => {
    if (isDirty) {
      let { privateKey, wallets, contractAddress, percent } = formData;
      const prc = percent?.toString().replace(/,/g, '') ?? '';
      const wls = wallets?.split(/\r?\n/) ?? [];

      if (contractAddress && !validatePublicKey(contractAddress)) {
        contractRef.current = setErrorWithTime(() =>
          setError('contractAddress', {
            message: 'This address is invalid',
          }),
        );
      } else if (contractAddress) {
        clearErrors('contractAddress');
      }
      let totalWalletError = 0;
      wls.forEach((it: string) => {
        if (it && !validatePublicKey(it)) {
          totalWalletError++;
          walletRef.current = setErrorWithTime(() =>
            setError('wallets', {
              message: 'This wallet is invalid',
            }),
          );
        }
      });
      if (!totalWalletError && wallets) {
        clearErrors('wallets');
      }
      if (prc && (+prc < 1 || +prc > 100)) {
        percentRef.current = setErrorWithTime(() =>
          setError('percent', {
            message: 'This percentage is invalid',
          }),
        );
      } else if (prc) {
        clearErrors('percent');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const stopFunction = () => {
    clearTimeout(privateKeyRef.current);
    clearTimeout(percentRef.current);
    clearTimeout(walletRef.current);
    clearTimeout(contractRef.current);
  };

  useEffect(() => {
    return () => {
      requestResetToken();
      stopFunction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const address = getAddressFromPrivateKey(watchValuePrivateKey);
    if (watchValuePrivateKey) {
      if (isValidKey) {
        setValidErrorValue('');
      } else {
        setValidErrorValue('Private key is not valid');
      }
    }
    setAddress(address);
  }, [watchValuePrivateKey]);

  return (
    <Container className="big">
      {multiToken?.tools && multiToken?.tools?.length > 0 && (
        <Box mb={4}>
          <Alert
            severity="warning"
            sx={{
              color: '#FFA500',
              '& a': { color: '#FFA07A', fontWeight: 600 },
            }}
          >
            We have sent the token, please check the transaction. If the
            transaction fails, you can use &nbsp;
            <a
              href="https://cointool.app/multiSender/sol"
              target="_blank"
              rel="noreferrer"
            >
              cointool
            </a>
            &nbsp; to send tokens with the syntax below:
          </Alert>
          <JumboTron mt={4} mb={4}>
            <Title>Cointool multi sender syntax</Title>
            {multiToken?.tools?.map((tool: string, idx: number) => (
              <>
                <Text className="white">{tool}</Text>
                <br />
              </>
            ))}
          </JumboTron>
        </Box>
      )}

      {multiToken?.tx?.length > 0
        ? multiToken?.tx?.map((tx: string, idx: number) => (
            <Alert
              key={idx}
              severity="success"
              sx={{
                margin: '10px 0 30px',
                '& a': {
                  color: '#fff',
                },
              }}
            >
              <a
                href={`https://solscan.io/tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >{`https://solscan.io/tx/${tx}`}</a>
            </Alert>
          ))
        : ''}

      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <Box
          component="div"
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Title>Send token</Title>
        </Box>

        <InputContainer>
          <Controller
            control={control}
            name="privateKey"
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label={'Owner Private Key'}
                  isRequired
                  error={validErrorValue || errors?.privateKey?.message}
                  value={value}
                  onChange={onChange}
                  type={'password'}
                  addressWallet={address}
                />
              );
            }}
          />
        </InputContainer>

        <InputContainer className="single">
          <Controller
            control={control}
            name="wallets"
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <TextArea
                  label={'Wallet'}
                  isRequired
                  error={errors?.wallets?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>
        <InputContainer className="single">
          <Controller
            control={control}
            name="contractAddress"
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Input
                  label="Token Address"
                  isRequired
                  error={errors?.contractAddress?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            control={control}
            name="percent"
            rules={{
              required: 'This field is required',
              max: {
                value: '100',
                message: 'must be no greater than 100 ',
              },
              min: {
                value: '1',
                message: 'must be no less than 1',
              },
            }}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <SliderItem
                  isRequired
                  label={'Percentage'}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </InputContainer>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={'Send Token'}
            className="multi_color_btn main_btn"
          />
        </Box>
      </Box>

      <CommonLoading show={loading} />
    </Container>
  );
};
