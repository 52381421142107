/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import Input from '@/components/form/input';
import { MonacoTextarea } from '@/components/form/monacoTextArea';
import { InputContainer } from '@/components/form/styled';
import BookIcon from '@/components/icons/Book';
import { Title } from '@/components/login';
import { StatusCode } from '@/constants/API';
import { EWithdrawForms } from '@/enums/holder';
import useGlobalHook from '@/hooks/useGlobalHook';
import { mainRequest } from '@/services';
import { targetSubjectDocuments, validatePublicKey } from '@/utils/helper';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface OkxWithdrawFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OkxWithdrawForm = ({ setLoading }: OkxWithdrawFormProps) => {
  const { t } = useTranslation();
  const [customValueError, setCustomValueError] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    apiKey: string;
    secretKey: string;
    passphrase: string;
    addresses: string;
  }>({});

  const watchValueWallet = watch('addresses');
  const valueRowWallet = watchValueWallet?.split(/\r?\n/) ?? [];
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const submitForm = async (formDt: any) => {
    const valueValid = handleValidAddressAndNumber(valueRowWallet);
    setLoading(true);
    try {
      formDt.addresses = formDt.addresses?.split(/\r?\n/) ?? [];
      const response = await mainRequest(`/okx/withdraw`, formDt, 'post');
      if (response.status === StatusCode.SUCCESS_CREATED) {
        setSuccessMsg([t('OKX Exchange Bulk Withdrawal successfully!')]);
        // reset({
        //   apiKey: '',
        //   secretKey: '',
        //   passphrase: '',
        //   addresses: '',
        // });
      } else {
        setErrorMsg([
          t(
            'Something went wrong. OKX Exchange Bulk Withdrawal unsuccessfully!',
          ),
        ]);
      }
    } catch (error) {
      setErrorMsg([
        t('Something went wrong. OKX Exchange Bulk Withdrawal unsuccessfully!'),
      ]);
    } finally {
      setLoading(false);
    }
  };
  const handleValidAddressAndNumber = value => {
    let errorCount = 0;
    let errorLines: any = [];
    const handleValue = value?.map((item, index) => {
      const [address, amount] = value[index].split(',');
      const validateKeyAndNumber =
        validatePublicKey(address) &&
        !isNaN(parseFloat(amount)) &&
        parseFloat(amount) == amount;

      console.log(!isNaN(parseFloat(amount)));
      if (!validateKeyAndNumber) {
        errorCount++;
        errorLines.push(index + 1);
      }

      return {
        receiver: address.trim(),
        amount: amount,
      };
    });

    return {
      isValid: errorCount === 0,
      errorLines: errorLines,
      messageError: `Check the address or SOL amount on line ${errorLines.join(
        ' , ',
      )}`,
      handleValue,
    };
  };
  useEffect(() => {
    const valueValid = handleValidAddressAndNumber(valueRowWallet);
    if (!valueValid.isValid && watchValueWallet) {
      setCustomValueError(valueValid.messageError);
    } else {
      setCustomValueError('');
    }
  }, [watchValueWallet]);
  return (
    <Box component="form">
      <Box
        component="div"
        mb={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title>OKX Exchange Bulk Withdrawal</Title>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <BookIcon />
          <Typography variant="subtitle2" sx={{ fontStyle: 'italic' }}>
            <Link
              to={targetSubjectDocuments[EWithdrawForms.OKX]}
              target="_blank"
            >
              <span>User guide</span>
            </Link>
          </Typography>
        </Stack>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer>
            <Controller
              control={control}
              name="apiKey"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('API Key')}
                    isRequired
                    error={errors?.apiKey?.message}
                    value={value}
                    onChange={onChange}
                    placeholder="API Key"
                    type="password"
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="secretKey"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Secret Key')}
                    isRequired
                    error={errors?.secretKey?.message}
                    value={value}
                    onChange={onChange}
                    placeholder="Secret Key"
                    type="password"
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
      </Grid>
      <InputContainer className="single">
        <Controller
          control={control}
          name="passphrase"
          rules={{
            required: t('This field is required'),
          }}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                label={t('Passphrase')}
                isRequired
                error={errors?.passphrase?.message}
                value={value}
                onChange={onChange}
                placeholder="Passphrase"
                type="password"
              />
            );
          }}
        />
      </InputContainer>
      <InputContainer className="single">
        <Controller
          control={control}
          name="addresses"
          rules={{
            required: t('This field is required'),
          }}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <MonacoTextarea
                label={t('Address,Amount List')}
                value={value}
                onChange={onChange}
                error={errors?.addresses?.message}
                customError={customValueError}
                description={'*Please enter: address,amount'}
                subDescription={
                  'example: FPRpqZcJoXKHuw3Gpr58qRDUccvJVBTvEDbh3mSpD6xu,1'
                }
              />
            );
          }}
        />
      </InputContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title="Run"
          className="multi_color_btn main_btn"
          onClick={handleSubmit(submitForm)}
        />
      </Box>
    </Box>
  );
};
