import { checkIsRequesting } from '@/utils/helper';
import React, { useEffect, useMemo, useState } from 'react';
import useAuthHook from '@/hooks/useAuthHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { Loader } from '@/components/common/loader';
import { RequestStatus } from '@/constants/API';
import { useNavigate } from 'react-router-dom';
import Path from '@/constants/path';
import { getToken } from '@/utils/localStorage';
import {
  Container,
  FormGroup,
  LoginContainer,
  LoginWrapper,
  SignUpButton,
  SubmitButton,
  Title,
} from '@/components/login';
import { LogoWrapper } from '@/components/layouts/sidebar/components';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/logo_mm.png';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CommonLoading from '@/components/common/CommonLoading';

export interface LoginFormState {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  const { loginRequestStatus, requestLogin } = useAuthHook();
  const { setErrorMsg } = useGlobalHook();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const loading = useMemo(
    () => checkIsRequesting([loginRequestStatus]),
    [loginRequestStatus],
  );

  const token = getToken();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm },
  }: any = useForm();

  useEffect(() => {
    if (loginRequestStatus === RequestStatus.SUCCESS || token) {
      setTimeout(() => navigate(Path.HOME), 1000);
    }
    if (loginRequestStatus === RequestStatus.ERROR) {
      setErrorMsg(['Wrong username/email or password']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginRequestStatus, token]);

  const onSubmit = (data, event) => {
    event.preventDefault();
    requestLogin(data);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Container>
      <LogoWrapper className="big_logo" to={Path.HOME}>
        <img src={logo} className="App-logo" alt="logo" />
      </LogoWrapper>

      <LoginContainer>
        <LoginWrapper>
          <Title>{t('Login')}</Title>
          <Box component="form" onSubmit={handleSubmitForm(onSubmit)}>
            <FormGroup>
              <TextField
                sx={{
                  background: '#fff',
                }}
                fullWidth
                label={t('label.email')}
                name="email"
                margin="normal"
                {...register('email', {
                  required: t('message.error.inputRequired', {
                    param: t('label.email'),
                  }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('message.error.emailRegex'),
                  },
                })}
                error={!!errorsForm.email}
                helperText={errorsForm.email ? errorsForm.email.message : ''}
              />
            </FormGroup>
            <div>
              <FormGroup>
                <FormControl sx={{ background: '#fff', width: '100%' }}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t('label.password')}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseUpPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('label.password')}
                  />
                </FormControl>
              </FormGroup>
            </div>
            <SubmitButton className="multi_color_btn main_btn" type="submit">
              {t('label.signIn')}
            </SubmitButton>
            <SignUpButton
              className="main_btn btn_sign_up"
              onClick={() => {
                navigate(Path.SIGN_UP);
              }}
            >
              {t('label.signUp')}
            </SignUpButton>
          </Box>
        </LoginWrapper>
      </LoginContainer>
      <CommonLoading show={loading} />
    </Container>
  );
};

export default LoginPage;
