/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import { Loader } from '@/components/common/loader';
import { Container } from '@/components/token/components';
import { OkxWithdrawForm } from '@/components/token/components/okxWithdrawForm';
import useTokenHook from '@/hooks/useTokenHook';
import { useEffect, useMemo, useState } from 'react';

export const OkxExchangePage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { requestResetToken } = useTokenHook();

  useEffect(() => {
    return () => requestResetToken();
  }, []);

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="medium">
      <OkxWithdrawForm setLoading={setLoading} />
      <CommonLoading show={loading} />
    </Container>
  );
};
