import React from 'react';

const BurnIcon: React.FC = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
  >
    <title>Fire</title>
    <path
      id="Fire"
      d="M17.35,3a12.08,12.08,0,0,0-4.72-3,.5.5,0,0,0-.52.16.5.5,0,0,0-.06.54,9,9,0,0,1,.83,6.4.16.16,0,0,1-.15.12c-.09,0-.11,0-.11-.07a10.31,10.31,0,0,0-3.7-4.57.5.5,0,0,0-.76.54c.59,2.31-.57,3.7-2,5.46S3,12.4,3,15.72a9.47,9.47,0,0,0,7.38,9.08A3.26,3.26,0,0,1,9,22.17c0-5.34,3.5-6.67,3.5-6.67C13.2,19,16,19.8,16,22.17a3.26,3.26,0,0,1-1.35,2.61,9.5,9.5,0,0,0,4-1.93A9.26,9.26,0,0,0,22,15.69C22,9,19.47,5.1,17.35,3Z"
      fill="#A43399"
    />
  </svg>
);

export default BurnIcon;
