import React from 'react';

const GateIO: React.FC = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <path d="M59,15v-.58a5.5,5.5,0,0,0-11,0V15a1,1,0,0,0-1,1v4H17V16a1,1,0,0,0-1-1v-.58a5.5,5.5,0,0,0-11,0V15a1,1,0,0,0-1,1V59a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V58H47v1a1,1,0,0,0,1,1H59a1,1,0,0,0,1-1V16A1,1,0,0,0,59,15Zm-9-.58a3.5,3.5,0,0,1,7,0V15H50Zm-43,0a3.5,3.5,0,0,1,7,0V15H7ZM15,58H6V17h9Zm2-2V22h2V56Zm4,0V22h2V56Zm4,0V22h2V56Zm4-34h2V37a1,1,0,0,0,0,2V56H29Zm4,34V39a1,1,0,0,0,0-2V22h2V56Zm4,0V22h2V56Zm4,0V22h2V56Zm4,0V22h2V56Zm13,2H49V17h9Z" />
      <path d="M27.76,7.76a6,6,0,0,1,8.49,0,.93.93,0,0,0,1.41,0,1,1,0,0,0,0-1.41,8,8,0,0,0-11.31,0,1,1,0,0,0,1.41,1.41Z" />
      <path d="M35.54,9.87a1,1,0,0,0,0-1.41,5,5,0,0,0-7.06,0,1,1,0,1,0,1.4,1.42,3.07,3.07,0,0,1,4.26,0A1.06,1.06,0,0,0,35.54,9.87Z" />
      <path d="M30.6,10.56a1,1,0,0,0,0,1.41A1,1,0,0,0,32,12a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41A2,2,0,0,0,30.6,10.56Z" />
    </svg>
  );
};

export default GateIO;
