import PopUp from '@/components/common/Popup';
import Input from '@/components/form/input';
import { MonacoTextarea } from '@/components/form/monacoTextArea';
import { InputContainer } from '@/components/form/styled';
import { EDialogTypes } from '@/enums/holder';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { IHolderDialogProps } from '@/interfaces/holder';
import {
  importHolderTokenRequest,
  importHolderWalletRequest,
} from '@/stores/slices/token/token';
import { validatePublicKey } from '@/utils/helper';
import { Box, Stack } from '@mui/material';
import { isEmpty, sortBy, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type FormValues = { addresses: string } | { id: number; addresses: string };

const HolderPopups = ({ isOpen, dialogType, onClose }: IHolderDialogProps) => {
  const { t } = useTranslation();
  const { id = 0 } = useParams();
  const dispatch = useAppDispatch();
  const [externalError, setExternalError] = useState<{
    isError: boolean;
    errorLines: number[];
  }>({
    isError: false,
    errorLines: [],
  });
  const { listHolderTokens, holderTokenDetail } = useAppSelector(
    state => state.token,
  );

  const formInitialValues = useMemo(() => {
    switch (dialogType) {
      case EDialogTypes.IMPORT_HOLDER_TOKEN:
        return {
          addresses: '',
        };
      case EDialogTypes.IMPORT_HOLDER_WALLET:
        return {
          id: 0,
          addresses: '',
        };
    }
  }, [dialogType]);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: formInitialValues,
  });

  const dialogTitle = useMemo(() => {
    switch (dialogType) {
      case EDialogTypes.IMPORT_HOLDER_TOKEN:
        return t('title.holder.popups.import_token_title');
      case EDialogTypes.IMPORT_HOLDER_WALLET:
        return t('title.holder.popups.import_wallet_title');
      default:
        return 'Default title';
    }
  }, [dialogType]);

  const dialogContent = () => {
    switch (dialogType) {
      case EDialogTypes.IMPORT_HOLDER_TOKEN:
        return (
          <InputContainer className="single">
            <Controller
              control={control}
              name="addresses"
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t('placeholder.placeholder.enterParam', {
                    param: t('label.contractAddress'),
                  })}
                  labelName={t('label.contractAddress')}
                  isRequired
                  error={errors.addresses?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </InputContainer>
        );
      case EDialogTypes.IMPORT_HOLDER_WALLET:
        return (
          <InputContainer className="single">
            <Controller
              control={control}
              name="addresses"
              rules={{
                validate: addressValidator,
              }}
              render={({ field: { value, onChange } }) => (
                <MonacoTextarea
                  label={t('label.addresses')}
                  value={value}
                  onChange={(e: any) => {
                    setExternalError({
                      ...externalError,
                      isError: false,
                    });
                    onChange(e);
                    trigger('addresses');
                  }}
                  error={errors.addresses?.message}
                  description={'*Please enter: address'}
                  subDescription={
                    'Example: FPRpqZcJoXKHuw3Gpr58qRDUccvJVBTvEDbh3mSpD6xu'
                  }
                />
              )}
            />
          </InputContainer>
        );

      default:
        return <></>;
    }
  };
  const renderErrorLines = (
    lines: number[] = [],
    isClient: boolean = true,
  ): string =>
    isClient
      ? `Check the address on line ${lines.join(', ')}`
      : `Address existed on line  ${lines.join(', ')}`;

  const importTokenHandler = (addresses: string) => {
    const submitData = {
      addresses: [addresses],
      params: listHolderTokens.params,
    };
    dispatch(
      importHolderTokenRequest({
        ...submitData,
        cb: () => {
          onClose();
          reset(formInitialValues);
        },
      }),
    );
  };

  const importWalletHandler = (addresses: string[]) => {
    const submitData = {
      tokenId: +id,
      addresses,
      params: holderTokenDetail.params,
    };
    dispatch(
      importHolderWalletRequest({
        ...submitData,
        cb: () => {
          onClose();
          reset(formInitialValues);
        },
        showErrors: (errorLines: number[]) => {
          setExternalError({
            isError: !isEmpty(errorLines),
            errorLines,
          });
          setError('addresses', {
            type: 'manual',
            message: renderErrorLines(errorLines, false),
          });
        },
      }),
    );
  };

  const addressValidator = (value: string) => {
    const rows = value?.split(/\r?\n/) ?? [];
    const errorRows = rows.reduce<{
      validRows: string[];
      invalidRows: number[];
      duplicateRows: number[];
    }>(
      (result, row, index) => {
        if (!validatePublicKey(row)) {
          result.invalidRows.push(index + 1);
        }
        if (result.validRows.includes(row)) {
          result.duplicateRows.push(index + 1);
        } else {
          result.validRows.push(row);
        }
        return result;
      },
      {
        validRows: [],
        invalidRows: [],
        duplicateRows: [],
      },
    );

    if (!rows.length) {
      return 'This field is required';
    }

    if (errorRows.invalidRows.length || errorRows.duplicateRows.length) {
      const sortedErrorRows = sortBy(
        errorRows.invalidRows.concat(errorRows.duplicateRows),
      );
      return renderErrorLines(uniq(sortedErrorRows));
    }

    if (externalError.isError) {
      return renderErrorLines(externalError.errorLines, false);
    }

    return true;
  };

  const onSubmitDialog = (formData: FormValues) => {
    switch (dialogType) {
      case EDialogTypes.IMPORT_HOLDER_TOKEN:
        importTokenHandler(formData.addresses);
        break;
      case EDialogTypes.IMPORT_HOLDER_WALLET:
        const formattedAddresses =
          formData.addresses
            ?.split(/\r?\n/)
            .filter(address => !isEmpty(address)) ?? [];
        importWalletHandler(formattedAddresses);
        break;
    }
  };

  const onCancel = () => {
    onClose?.();
    reset();
  };

  return (
    <PopUp
      title={dialogTitle}
      onClose={onClose}
      open={isOpen}
      className="modal-default"
    >
      <Box component="form" onSubmit={handleSubmit(onSubmitDialog)}>
        {dialogContent()}

        <Stack
          sx={{
            width: '100%',
            my: 2,
          }}
          spacing={2}
          direction="row"
          justifyContent="end"
        >
          <button
            className="main_btn button new_btn multi_color_btn secondary_btn"
            onClick={onCancel}
          >
            Cancel
          </button>

          <button
            className="main_btn button new_btn multi_color_btn"
            type="submit"
          >
            Submit
          </button>
        </Stack>
      </Box>
    </PopUp>
  );
};

export default HolderPopups;
