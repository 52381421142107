import { Controller, useForm } from 'react-hook-form';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Input from '@/components/form/input';
import { InputContainer } from '@/components/form/styled';
import { MonacoTextarea } from '@/components/form/monacoTextArea';
import {
  targetSubjectDocuments,
  targetSubjectWithdraw,
  validatePublicKey,
} from '@/utils/helper';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { REGEX } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { withdrawRequest } from '@/stores/slices/token/token';
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import CommonLoading from '@/components/common/CommonLoading';
import { RequestStatus } from '@/constants/API';
import { EWithdrawForms } from '@/enums/holder';
import React from 'react';
import { Link } from 'react-router-dom';
import BookIcon from '@/components/icons/Book';

const initWithdrawValues: IWithdrawForm = {
  apiKey: '',
  secretKey: '',
  addresses: '',
};

interface WithdrawFormProps {
  mode: EWithdrawForms;
}

interface IWithdrawForm {
  apiKey: string;
  secretKey: string;
  addresses: string;
  passphrase?: string;
}

const WithdrawForm: React.FC<WithdrawFormProps> = ({ mode }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { withdraw } = useAppSelector(state => state.token);
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IWithdrawForm>({
    defaultValues: initWithdrawValues,
  });

  const addressAndAmountValidator = (value: string) => {
    const rows = value?.split(/\r?\n/) ?? [];
    const errorRows = rows.reduce<number[]>((errors, row, index) => {
      const [address, amount] = row.split(',');
      if (
        !REGEX.ADDRESS_AND_AMOUNT.test(row) ||
        !validatePublicKey(address) ||
        isNaN(+amount)
      ) {
        errors.push(index + 1);
      }
      return errors;
    }, []);

    if (!rows.length) {
      return 'This field is required';
    }

    if (errorRows.length) {
      return `Check the address or SOL amount on line ${errorRows.join(', ')}`;
    }

    return true;
  };

  const submitHandler = (formData: IWithdrawForm) => {
    const formattedAddresses =
      formData.addresses?.split(/\r?\n/).filter(address => !isEmpty(address)) ??
      [];
    dispatch(
      withdrawRequest({
        formData: {
          ...formData,
          addresses: formattedAddresses,
        },
        mode,
        messages: {
          success: `${targetSubjectWithdraw[mode]} Withdrawal successfully!`,
          failed: `Something went wrong. ${targetSubjectWithdraw[mode]} Withdrawal unsuccessfully!`,
        },
        callback: () => reset(initWithdrawValues),
      }),
    );
  };

  return (
    <>
      <Stack
        sx={{ mb: 3 }}
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={1}
      >
        <BookIcon />
        <Typography variant="subtitle2" sx={{ fontStyle: 'italic' }}>
          <Link to={targetSubjectDocuments[mode]} target="_blank">
            <span>User guide</span>
          </Link>
        </Typography>
      </Stack>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputContainer>
              <Controller
                control={control}
                name="apiKey"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label="API Key"
                    isRequired
                    error={errors?.apiKey?.message}
                    value={value}
                    onChange={onChange}
                    placeholder="API Key"
                    type="password"
                  />
                )}
              />
            </InputContainer>
          </Grid>
          <Grid item xs={6}>
            <InputContainer>
              <Controller
                control={control}
                name="secretKey"
                rules={{
                  required: 'This field is required',
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label="Secret Key"
                    isRequired
                    error={errors?.secretKey?.message}
                    value={value}
                    onChange={onChange}
                    placeholder="Secret Key"
                    type="password"
                  />
                )}
              />
            </InputContainer>
          </Grid>
        </Grid>
        {mode === EWithdrawForms.BITGET && (
          <InputContainer className="single">
            <Controller
              control={control}
              name="passphrase"
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  label="Passphrase"
                  isRequired
                  error={errors?.secretKey?.message}
                  value={value}
                  onChange={onChange}
                  placeholder="Passphrase"
                  type="password"
                />
              )}
            />
          </InputContainer>
        )}
        <InputContainer className="single">
          <Controller
            control={control}
            name="addresses"
            rules={{
              validate: addressAndAmountValidator,
            }}
            render={({ field: { value, onChange } }) => (
              <MonacoTextarea
                label="Address,Amount List"
                value={value}
                onChange={(e: any[]) => {
                  onChange(e);
                  trigger('addresses');
                }}
                error={errors?.addresses?.message}
                description={'*Please enter: address,amount'}
                subDescription={
                  'Example: FPRpqZcJoXKHuw3Gpr58qRDUccvJVBTvEDbh3mSpD6xu,1'
                }
              />
            )}
          />
        </InputContainer>

        <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title="Run"
            className="multi_color_btn main_btn text-center"
            onClick={handleSubmit(submitHandler)}
          />
        </Stack>
        <CommonLoading show={withdraw.status === RequestStatus.REQUESTING} />
      </Box>
    </>
  );
};

export default WithdrawForm;
