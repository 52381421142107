enum Path {
  LOGIN = '/login',
  SIGN_UP = '/sign_up',
  HOME = '/',
  CREATE_TOKEN = '/create_token',
  TOKEN_DETAILS = '/tokens/:id',
  TOKEN_LIST = '/tokens',

  BOOT_BUY = '/raydium/boot_tx',
  BOOT_BUY_TX = '/raydium/boot_tx/buy/:id',
  BOOT_SELL_TX = '/raydium/boot_tx/sell/:id',
  BOOT_VOLUME = '/raydium/boot_tx/volume',
  BOOT_VOLUME_TX = '/raydium/boot_tx/volume/:id',

  BOOT_TX_PUMP = '/pumpfun/boot_tx',
  BOOT_BUY_TX_PUMP = '/pumpfun/boot_tx/buy/:id',
  BOOT_SELL_TX_PUMP = '/pumpfun/boot_tx/sell/:id',
  BOOT_VOLUME_PUMP = '/pumpfun/boot_tx/volume',
  BOOT_VOLUME_TX_PUMP = '/pumpfun/boot_tx/volume/:id',
  BOOT_SELL = '/boot_sell_tx',
  SEND_SOL = '/send_sol',
  GEN_WALLET = '/generate_wallet',
  MULTI_SENDER = '/multi_sender',
  CREATE_BOOK = '/create_book',
  CREATE_PUMPFUN_TOKEN = '/create_pumpfun_token',
  TRADERS = '/trader',
  TRADERS_DETAIL = '/trader/:id',
  PUMPFUN_TOKENS = '/pumpfun_tokens',
  CREATE_POOL = '/create_pool',

  CLAIM_SOL = '/claim_sol',
  OKX_EXCHANGE = '/okx_withdraw',
  HOLDER = '/holders',
  HOLDER_TOKEN_DETAIL = '/holders/tokens/:id',

  MEXC_WITHDRAW = '/mexc-withdraw',
  GATE_WITHDRAW = '/gate-withdraw',
  BINANCE_WITHDRAW = '/binance-withdraw',
  BYBIT_WITHDRAW = '/bybit-withdraw',
  BITGET_WITHDRAW = '/bitget-withdraw',
  BURN_TOKEN = '/burn-token',
  PUMPFUN_BUNDLE = '/pumpfun-bundle',
}

export default Path;
