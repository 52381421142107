/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { ImportBootTxPopup } from '@/components/token/components/importBootTx';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export interface DeleteForm {
  input: string;
}
export const BootTxPage = (props: { type: any }) => {
  const { type } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    getBootTxListStatus,
    requestBootTxList,
    bootTxList,
    requestImportBootTx,
    importBootTxStatus,
    bootVolumeTxList,
    requestImportBootVolumeTx,
    importBootVolumeTxStatus,
    getBootVolumeListStatus,
    requestBootVolumeTxList,
  } = useTokenHook();

  const reloadData = () => {
    if (type === TableType.BOOT_VOLUME) {
      requestBootVolumeTxList();
    } else {
      requestBootTxList();
    }
  };

  useEffect(() => {
    if (type === TableType.BOOT_VOLUME) {
      if (getBootVolumeListStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to fetch tokens')]);
      }
    } else {
      if (getBootTxListStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to fetch tokens')]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBootTxListStatus, getBootVolumeListStatus]);

  useEffect(() => {
    if (type === TableType.BOOT_VOLUME) {
      if (importBootVolumeTxStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to import token')]);
      }
      if (importBootVolumeTxStatus === RequestStatus.SUCCESS) {
        setSuccessMsg([t('Token is imported!')]);
        reloadData();
      }
    } else {
      if (importBootTxStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to import token')]);
      }
      if (importBootTxStatus === RequestStatus.SUCCESS) {
        setSuccessMsg([t('Token is imported!')]);
        reloadData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importBootTxStatus, importBootVolumeTxStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getBootTxListStatus,
        importBootTxStatus,
        getBootVolumeListStatus,
        importBootVolumeTxStatus,
      ]),
    [
      getBootTxListStatus,
      importBootTxStatus,
      getBootVolumeListStatus,
      importBootVolumeTxStatus,
    ],
  );

  const onImport = () => {
    setOpen(true);
  };
  const importToken = async (payload: any) => {
    if (type === TableType.BOOT_VOLUME) {
      await requestImportBootVolumeTx(payload);
    } else {
      await requestImportBootTx(payload);
    }
    setOpen(false);
  };

  return (
    <CommonContainer className="top">
      <Box
        mt={4}
        mb={4}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={onImport}
        >
          Import token boot tx
        </button>
      </Box>
      <CommonTable
        type={type}
        data={type === TableType.BOOT_VOLUME ? bootVolumeTxList : bootTxList}
        onReload={reloadData}
      />
      <ImportBootTxPopup
        open={open}
        onSubmit={importToken}
        onClose={() => setOpen(false)}
      />
      <CommonLoading show={loading} />
    </CommonContainer>
  );
};
