import { RequestStatus } from '@/constants/API';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface PumpState {
  importWalletBuyPumpStatus: RequestStatus;
  importWalletSellPumpStatus: RequestStatus;
  importWalletVolumePumpStatus: RequestStatus;
  getListWalletBuyPumpStatus: RequestStatus;
  getListWalletSellPumpStatus: RequestStatus;
  getListWalletVolumePumpStatus: RequestStatus;
  startFakeBuyPumpStatus: RequestStatus;
  startFakeSellPumpStatus: RequestStatus;
  startFakeVolumePumpStatus: RequestStatus;
  updateVolumeBuyPumpStatus: RequestStatus;
  updateVolumeSellPumpStatus: RequestStatus;
  updateVolumeVolumePumpStatus: RequestStatus;
  getPumpBootTxListStatus: RequestStatus;
  importPumpBootTxStatus: RequestStatus;
  getPumpBootVolumeTxListStatus: RequestStatus;
  importPumpBootVolumeTxStatus: RequestStatus;
  getListPumpTokenStatus: RequestStatus;
  claimSolStatus: RequestStatus;
  importPumpTokenStatus: RequestStatus;
  getListPumpWalletStatus: RequestStatus;
  importPumpWalletStatus: RequestStatus;
  deletePumpWalletStatus: RequestStatus;
  deleteAllPumpWalletStatus: RequestStatus;
  buyPumpWalletStatus: RequestStatus;
  sellPumpWalletStatus: RequestStatus;
  activeWalletStatus: RequestStatus;
  unActiveWalletStatus: RequestStatus;
  getSettingTradeStatus: RequestStatus;
  updateSettingTradeStatus: RequestStatus;
  getProfitTradeStatus: RequestStatus;
  deleteBootTxWalletStatus: RequestStatus;
  listWalletBuy: any[];
  listWalletSell: any[];
  listWalletVolume: any[];
  pumpBootTxList: any[];
  pumpBootVolumeTxList: [];
  listSignaturePumpBuy: any[];
  listSignaturePumpSell: any[];
  listSignaturePumpVolume: any[];
  listPumpToken: any[];
  listPumpWallet: any[];
  listTraderActive: any[];
  settingTrade: any[];
  listProfitTrade: any[];
}

const initialState: PumpState = {
  importWalletBuyPumpStatus: RequestStatus.IDLE,
  importWalletSellPumpStatus: RequestStatus.IDLE,
  importWalletVolumePumpStatus: RequestStatus.IDLE,
  getListWalletBuyPumpStatus: RequestStatus.IDLE,
  getListWalletSellPumpStatus: RequestStatus.IDLE,
  getListWalletVolumePumpStatus: RequestStatus.IDLE,
  startFakeBuyPumpStatus: RequestStatus.IDLE,
  startFakeSellPumpStatus: RequestStatus.IDLE,
  startFakeVolumePumpStatus: RequestStatus.IDLE,
  updateVolumeBuyPumpStatus: RequestStatus.IDLE,
  updateVolumeSellPumpStatus: RequestStatus.IDLE,
  updateVolumeVolumePumpStatus: RequestStatus.IDLE,
  getPumpBootTxListStatus: RequestStatus.IDLE,
  importPumpBootTxStatus: RequestStatus.IDLE,
  getPumpBootVolumeTxListStatus: RequestStatus.IDLE,
  importPumpBootVolumeTxStatus: RequestStatus.IDLE,
  claimSolStatus: RequestStatus.IDLE,
  getListPumpTokenStatus: RequestStatus.IDLE,
  importPumpTokenStatus: RequestStatus.IDLE,
  getListPumpWalletStatus: RequestStatus.IDLE,
  importPumpWalletStatus: RequestStatus.IDLE,
  deletePumpWalletStatus: RequestStatus.IDLE,
  deleteAllPumpWalletStatus: RequestStatus.IDLE,
  buyPumpWalletStatus: RequestStatus.IDLE,
  sellPumpWalletStatus: RequestStatus.IDLE,
  activeWalletStatus: RequestStatus.IDLE,
  unActiveWalletStatus: RequestStatus.IDLE,
  getSettingTradeStatus: RequestStatus.IDLE,
  updateSettingTradeStatus: RequestStatus.IDLE,
  getProfitTradeStatus: RequestStatus.IDLE,
  deleteBootTxWalletStatus: RequestStatus.IDLE,
  listWalletBuy: [],
  listWalletSell: [],
  listWalletVolume: [],
  pumpBootTxList: [],
  pumpBootVolumeTxList: [],
  listSignaturePumpBuy: [],
  listSignaturePumpSell: [],
  listSignaturePumpVolume: [],
  listPumpToken: [],
  listPumpWallet: [],
  listTraderActive: [],
  settingTrade: [],
  listProfitTrade: [],
};

export const pumpSlice: any = createSlice({
  name: 'pump',
  initialState,
  reducers: {
    importWalletBuyPumpFailure: (state, action: PayloadAction<any>) => {
      state.importWalletBuyPumpStatus = RequestStatus.ERROR;
    },
    importWalletBuyPumpRequest: (state, action: PayloadAction<any>) => {
      state.importWalletBuyPumpStatus = RequestStatus.REQUESTING;
    },
    importWalletBuyPumpSuccess: (state, action: PayloadAction<any>) => {
      state.importWalletBuyPumpStatus = RequestStatus.SUCCESS;
    },
    importWalletSellPumpFailure: (state, action: PayloadAction<any>) => {
      state.importWalletSellPumpStatus = RequestStatus.ERROR;
    },
    importWalletSellPumpRequest: (state, action: PayloadAction<any>) => {
      state.importWalletSellPumpStatus = RequestStatus.REQUESTING;
    },
    importWalletSellPumpSuccess: (state, action: PayloadAction<any>) => {
      state.importWalletSellPumpStatus = RequestStatus.SUCCESS;
    },
    importWalletVolumePumpFailure: (state, action: PayloadAction<any>) => {
      state.importWalletVolumePumpStatus = RequestStatus.ERROR;
    },
    importWalletVolumePumpRequest: (state, action: PayloadAction<any>) => {
      state.importWalletVolumePumpStatus = RequestStatus.REQUESTING;
    },
    importWalletVolumePumpSuccess: (state, action: PayloadAction<any>) => {
      state.importWalletVolumePumpStatus = RequestStatus.SUCCESS;
    },
    getListWalletBuyPumpFailure: (state, action: PayloadAction<any>) => {
      state.getListWalletBuyPumpStatus = RequestStatus.ERROR;
    },
    getListWalletBuyPumpRequest: (state, action: PayloadAction<any>) => {
      state.getListWalletBuyPumpStatus = RequestStatus.REQUESTING;
    },
    getListWalletBuyPumpSuccess: (state, action: PayloadAction<any>) => {
      state.getListWalletBuyPumpStatus = RequestStatus.SUCCESS;
      state.listWalletBuy = action.payload;
    },
    getListWalletSellPumpFailure: (state, action: PayloadAction<any>) => {
      state.getListWalletSellPumpStatus = RequestStatus.ERROR;
    },
    getListWalletSellPumpRequest: (state, action: PayloadAction<any>) => {
      state.getListWalletSellPumpStatus = RequestStatus.REQUESTING;
    },
    getListWalletSellPumpSuccess: (state, action: PayloadAction<any>) => {
      state.getListWalletSellPumpStatus = RequestStatus.SUCCESS;
      state.listWalletSell = action.payload;
    },
    getListWalletVolumePumpFailure: (state, action: PayloadAction<any>) => {
      state.getListWalletVolumePumpStatus = RequestStatus.ERROR;
    },
    getListWalletVolumePumpRequest: (state, action: PayloadAction<any>) => {
      state.getListWalletVolumePumpStatus = RequestStatus.REQUESTING;
    },
    getListWalletVolumePumpSuccess: (state, action: PayloadAction<any>) => {
      state.getListWalletVolumePumpStatus = RequestStatus.SUCCESS;
      state.listWalletVolume = action.payload;
    },
    startFakeBuyPumpRequest: (state, action: PayloadAction<any>) => {
      state.startFakeBuyPumpStatus = RequestStatus.REQUESTING;
    },
    startFakeBuyPumpSuccess: (state, action: PayloadAction<any>) => {
      state.startFakeBuyPumpStatus = RequestStatus.SUCCESS;
      state.listSignaturePumpBuy = action.payload;
    },
    startFakeBuyPumpFailure: (state, action: PayloadAction<any>) => {
      state.startFakeBuyPumpStatus = RequestStatus.ERROR;
    },
    startFakeSellPumpRequest: (state, action: PayloadAction<any>) => {
      state.startFakeSellPumpStatus = RequestStatus.REQUESTING;
    },
    startFakeSellPumpSuccess: (state, action: PayloadAction<any>) => {
      state.startFakeSellPumpStatus = RequestStatus.SUCCESS;
      state.listSignaturePumpSell = action.payload;
    },
    startFakeSellPumpFailure: (state, action: PayloadAction<any>) => {
      state.startFakeSellPumpStatus = RequestStatus.ERROR;
    },
    startFakeVolumePumpRequest: (state, action: PayloadAction<any>) => {
      state.startFakeVolumePumpStatus = RequestStatus.REQUESTING;
    },
    startFakeVolumePumpSuccess: (state, action: PayloadAction<any>) => {
      state.startFakeVolumePumpStatus = RequestStatus.SUCCESS;
      state.listSignaturePumpVolume = action.payload;
    },
    startFakeVolumePumpFailure: (state, action: PayloadAction<any>) => {
      state.startFakeVolumePumpStatus = RequestStatus.ERROR;
    },

    updateVolumeBuyPumpRequest: (state, action: PayloadAction<any>) => {
      state.updateVolumeBuyPumpStatus = RequestStatus.REQUESTING;
    },
    updateVolumeBuyPumpSuccess: (state, action: PayloadAction<any>) => {
      state.updateVolumeBuyPumpStatus = RequestStatus.SUCCESS;
    },
    updateVolumeBuyPumpFailure: (state, action: PayloadAction<any>) => {
      state.updateVolumeBuyPumpStatus = RequestStatus.ERROR;
    },
    updateVolumeSellPumpRequest: (state, action: PayloadAction<any>) => {
      state.updateVolumeSellPumpStatus = RequestStatus.REQUESTING;
    },
    updateVolumeSellPumpSuccess: (state, action: PayloadAction<any>) => {
      state.updateVolumeSellPumpStatus = RequestStatus.SUCCESS;
    },
    updateVolumeSellPumpFailure: (state, action: PayloadAction<any>) => {
      state.updateVolumeSellPumpStatus = RequestStatus.ERROR;
    },
    updateVolumeVolumePumpRequest: (state, action: PayloadAction<any>) => {
      state.updateVolumeVolumePumpStatus = RequestStatus.REQUESTING;
    },
    updateVolumeVolumePumpSuccess: (state, action: PayloadAction<any>) => {
      state.updateVolumeVolumePumpStatus = RequestStatus.SUCCESS;
    },
    updateVolumeVolumePumpFailure: (state, action: PayloadAction<any>) => {
      state.updateVolumeVolumePumpStatus = RequestStatus.ERROR;
    },

    getPumpBootTxListRequest: (state, action: PayloadAction<any>) => {
      state.getPumpBootTxListStatus = RequestStatus.REQUESTING;
    },
    getPumpBootTxListSuccess: (state, action: PayloadAction<any>) => {
      state.getPumpBootTxListStatus = RequestStatus.SUCCESS;
      state.pumpBootTxList = action.payload;
    },
    getPumpBootTxListFailure: state => {
      state.getPumpBootTxListStatus = RequestStatus.ERROR;
    },

    importPumpBootTxRequest: (state, action: PayloadAction<any>) => {
      state.importPumpBootTxStatus = RequestStatus.REQUESTING;
    },
    importPumpBootTxSuccess: (state, action: PayloadAction<any>) => {
      state.importPumpBootTxStatus = RequestStatus.SUCCESS;
    },
    importPumpBootTxFailure: (state, action: PayloadAction<any>) => {
      state.importPumpBootTxStatus = RequestStatus.ERROR;
    },

    getPumpBootVolumeTxListRequest: (state, action: PayloadAction<any>) => {
      state.getPumpBootVolumeTxListStatus = RequestStatus.REQUESTING;
    },
    getPumpBootVolumeTxListSuccess: (state, action: PayloadAction<any>) => {
      state.getPumpBootVolumeTxListStatus = RequestStatus.SUCCESS;
      state.pumpBootVolumeTxList = action.payload;
    },
    getPumpBootVolumeTxListFailure: state => {
      state.getPumpBootVolumeTxListStatus = RequestStatus.ERROR;
    },

    importPumpBootVolumeTxRequest: (state, action: PayloadAction<any>) => {
      state.importPumpBootVolumeTxStatus = RequestStatus.REQUESTING;
    },
    importPumpBootVolumeTxSuccess: (state, action: PayloadAction<any>) => {
      state.importPumpBootVolumeTxStatus = RequestStatus.SUCCESS;
    },
    importPumpBootVolumeTxFailure: (state, action: PayloadAction<any>) => {
      state.importPumpBootVolumeTxStatus = RequestStatus.ERROR;
    },

    claimSolRequest: (state, action: PayloadAction<any>) => {
      state.claimSolStatus = RequestStatus.REQUESTING;
    },
    claimSolSuccess: (state, action: PayloadAction<any>) => {
      state.claimSolStatus = RequestStatus.SUCCESS;
    },
    claimSolFailure: (state, action: PayloadAction<any>) => {
      state.claimSolStatus = RequestStatus.ERROR;
    },

    getListPumpTokenRequest: (state, action: PayloadAction<any>) => {
      state.getListPumpTokenStatus = RequestStatus.REQUESTING;
    },
    getListPumpTokenSuccess: (state, action: PayloadAction<any>) => {
      state.getListPumpTokenStatus = RequestStatus.SUCCESS;
      state.listPumpToken = action.payload;
    },
    getListPumpTokenFailure: (state, action: PayloadAction<any>) => {
      state.getListPumpTokenStatus = RequestStatus.ERROR;
    },

    importPumpTokenRequest: (state, action: PayloadAction<any>) => {
      state.importPumpTokenStatus = RequestStatus.REQUESTING;
    },
    importPumpTokenSuccess: (state, action: PayloadAction<any>) => {
      state.importPumpTokenStatus = RequestStatus.SUCCESS;
    },
    importPumpTokenFailure: (state, action: PayloadAction<any>) => {
      state.importPumpTokenStatus = RequestStatus.ERROR;
    },

    getListPumpWalletRequest: (state, action: PayloadAction<any>) => {
      state.getListPumpWalletStatus = RequestStatus.REQUESTING;
    },
    getListPumpWalletSuccess: (state, action: PayloadAction<any>) => {
      state.getListPumpWalletStatus = RequestStatus.SUCCESS;
      state.listPumpWallet = action.payload;
    },
    getListPumpWalletFailure: (state, action: PayloadAction<any>) => {
      state.getListPumpWalletStatus = RequestStatus.ERROR;
    },

    importPumpWalletRequest: (state, action: PayloadAction<any>) => {
      state.importPumpWalletStatus = RequestStatus.REQUESTING;
    },

    importPumpWalletSuccess: (state, action: PayloadAction<any>) => {
      state.importPumpWalletStatus = RequestStatus.SUCCESS;
    },
    importPumpWalletFailure: (state, action: PayloadAction<any>) => {
      state.importPumpWalletStatus = RequestStatus.ERROR;
    },

    deletePumpWalletRequest: (state, action: PayloadAction<any>) => {
      state.deletePumpWalletStatus = RequestStatus.REQUESTING;
    },

    deletePumpWalletSuccess: (state, action: PayloadAction<any>) => {
      state.deletePumpWalletStatus = RequestStatus.SUCCESS;
    },
    deletePumpWalletFailure: (state, action: PayloadAction<any>) => {
      state.deletePumpWalletStatus = RequestStatus.ERROR;
    },

    buyPumpWalletRequest: (state, action: PayloadAction<any>) => {
      state.buyPumpWalletStatus = RequestStatus.REQUESTING;
    },

    buyPumpWalletSuccess: (state, action: PayloadAction<any>) => {
      state.buyPumpWalletStatus = RequestStatus.SUCCESS;
    },
    buyPumpWalletFailure: (state, action: PayloadAction<any>) => {
      state.buyPumpWalletStatus = RequestStatus.ERROR;
    },

    sellPumpWalletRequest: (state, action: PayloadAction<any>) => {
      state.sellPumpWalletStatus = RequestStatus.REQUESTING;
    },

    sellPumpWalletSuccess: (state, action: PayloadAction<any>) => {
      state.sellPumpWalletStatus = RequestStatus.SUCCESS;
    },
    sellPumpWalletFailure: (state, action: PayloadAction<any>) => {
      state.sellPumpWalletStatus = RequestStatus.ERROR;
    },

    activeWalletRequest: (state, action: PayloadAction<any>) => {
      state.activeWalletStatus = RequestStatus.REQUESTING;
    },

    activeWalletSuccess: (state, action: PayloadAction<any>) => {
      state.activeWalletStatus = RequestStatus.SUCCESS;
    },
    activeWalletFailure: (state, action: PayloadAction<any>) => {
      state.activeWalletStatus = RequestStatus.ERROR;
    },

    unActiveWalletRequest: (state, action: PayloadAction<any>) => {
      state.unActiveWalletStatus = RequestStatus.REQUESTING;
    },

    unActiveWalletSuccess: (state, action: PayloadAction<any>) => {
      state.unActiveWalletStatus = RequestStatus.SUCCESS;
    },
    unActiveWalletFailure: (state, action: PayloadAction<any>) => {
      state.unActiveWalletStatus = RequestStatus.ERROR;
    },

    getSettingTradeRequest: (state, action: PayloadAction<any>) => {
      state.getSettingTradeStatus = RequestStatus.REQUESTING;
    },
    getSettingTradeSuccess: (state, action: PayloadAction<any>) => {
      state.getSettingTradeStatus = RequestStatus.SUCCESS;
      state.settingTrade = action.payload;
    },
    getSettingTradeFailure: (state, action: PayloadAction<any>) => {
      state.getSettingTradeStatus = RequestStatus.ERROR;
    },

    updateSettingTradeRequest: (state, action: PayloadAction<any>) => {
      state.updateSettingTradeStatus = RequestStatus.REQUESTING;
    },
    UpdateSettingTradeSuccess: (state, action: PayloadAction<any>) => {
      state.updateSettingTradeStatus = RequestStatus.SUCCESS;
    },
    updateSettingTradeFailure: (state, action: PayloadAction<any>) => {
      state.updateSettingTradeStatus = RequestStatus.ERROR;
    },

    getProfitTradeRequest: (state, action: PayloadAction<any>) => {
      state.getProfitTradeStatus = RequestStatus.REQUESTING;
    },
    getProfitTradeSuccess: (state, action: PayloadAction<any>) => {
      state.getProfitTradeStatus = RequestStatus.SUCCESS;
      state.listProfitTrade = action.payload;
    },
    getProfitTradeFailure: (state, action: PayloadAction<any>) => {
      state.getProfitTradeStatus = RequestStatus.ERROR;
    },

    deleteBootTxWalletRequest: (state, action: PayloadAction<any>) => {
      state.deleteBootTxWalletStatus = RequestStatus.REQUESTING;
    },

    deleteBootTxWalletSuccess: (state, action: PayloadAction<any>) => {
      state.deleteBootTxWalletStatus = RequestStatus.SUCCESS;
    },
    deleteBootTxWalletFailure: (state, action: PayloadAction<any>) => {
      state.deleteBootTxWalletStatus = RequestStatus.ERROR;
    },

    resetPumpInfoRequestStatus: state => {
      state.importWalletBuyPumpStatus = RequestStatus.IDLE;
      state.importWalletSellPumpStatus = RequestStatus.IDLE;
      state.importWalletVolumePumpStatus = RequestStatus.IDLE;
      state.getListWalletBuyPumpStatus = RequestStatus.IDLE;
      state.getListWalletSellPumpStatus = RequestStatus.IDLE;
      state.getListWalletVolumePumpStatus = RequestStatus.IDLE;
      state.startFakeBuyPumpStatus = RequestStatus.IDLE;
      state.startFakeSellPumpStatus = RequestStatus.IDLE;
      state.startFakeVolumePumpStatus = RequestStatus.IDLE;
      state.updateVolumeBuyPumpStatus = RequestStatus.IDLE;
      state.updateVolumeSellPumpStatus = RequestStatus.IDLE;
      state.updateVolumeVolumePumpStatus = RequestStatus.IDLE;
      state.getPumpBootTxListStatus = RequestStatus.IDLE;
      state.importPumpBootTxStatus = RequestStatus.IDLE;
      state.getPumpBootVolumeTxListStatus = RequestStatus.IDLE;
      state.importPumpBootVolumeTxStatus = RequestStatus.IDLE;
      state.getListPumpTokenStatus = RequestStatus.IDLE;
      state.importPumpTokenStatus = RequestStatus.IDLE;
      state.getListPumpWalletStatus = RequestStatus.IDLE;
      state.importPumpWalletStatus = RequestStatus.IDLE;
      state.deletePumpWalletStatus = RequestStatus.IDLE;
      state.activeWalletStatus = RequestStatus.IDLE;
      state.unActiveWalletStatus = RequestStatus.IDLE;
      state.buyPumpWalletStatus = RequestStatus.IDLE;
      state.sellPumpWalletStatus = RequestStatus.IDLE;
      state.getProfitTradeStatus = RequestStatus.IDLE;
      state.deleteBootTxWalletStatus = RequestStatus.IDLE;
      state.listWalletSell = [];
      state.listWalletVolume = [];
      state.pumpBootTxList = [];
      state.pumpBootVolumeTxList = [];
      state.listSignaturePumpBuy = [];
      state.listSignaturePumpSell = [];
      state.listSignaturePumpVolume = [];
      state.listPumpToken = [];
      state.listPumpWallet = [];
      state.listProfitTrade = [];
    },
    updateListTraderActive: (state, action: PayloadAction<any>) => {
      if (action.payload.active === 'reset') {
        state.listTraderActive = [];
      } else if (action.payload.active) {
        state.listTraderActive = [
          ...state.listTraderActive,
          action.payload.data,
        ];
      } else {
        state.listTraderActive = state.listTraderActive.filter(
          item => item.id !== action.payload.data.id,
        );
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPumpInfoRequestStatus,
  importWalletBuyPumpFailure,
  importWalletBuyPumpRequest,
  importWalletBuyPumpSuccess,
  importWalletSellPumpFailure,
  importWalletSellPumpRequest,
  importWalletSellPumpSuccess,
  importWalletVolumePumpFailure,
  importWalletVolumePumpRequest,
  importWalletVolumePumpSuccess,
  getListWalletBuyPumpFailure,
  getListWalletBuyPumpRequest,
  getListWalletBuyPumpSuccess,
  getListWalletSellPumpFailure,
  getListWalletSellPumpRequest,
  getListWalletSellPumpSuccess,
  getListWalletVolumePumpFailure,
  getListWalletVolumePumpRequest,
  getListWalletVolumePumpSuccess,
  startFakeBuyPumpRequest,
  startFakeBuyPumpSuccess,
  startFakeBuyPumpFailure,
  startFakeSellPumpRequest,
  startFakeSellPumpSuccess,
  startFakeSellPumpFailure,
  startFakeVolumePumpRequest,
  startFakeVolumePumpSuccess,
  startFakeVolumePumpFailure,
  updateVolumeBuyPumpRequest,
  updateVolumeBuyPumpSuccess,
  updateVolumeBuyPumpFailure,
  updateVolumeSellPumpRequest,
  updateVolumeSellPumpSuccess,
  updateVolumeSellPumpFailure,
  updateVolumeVolumePumpRequest,
  updateVolumeVolumePumpSuccess,
  updateVolumeVolumePumpFailure,
  getPumpBootTxListRequest,
  getPumpBootTxListSuccess,
  getPumpBootTxListFailure,
  importPumpBootTxRequest,
  importPumpBootTxSuccess,
  importPumpBootTxFailure,
  getPumpBootVolumeTxListRequest,
  getPumpBootVolumeTxListSuccess,
  getPumpBootVolumeTxListFailure,
  importPumpBootVolumeTxRequest,
  importPumpBootVolumeTxSuccess,
  importPumpBootVolumeTxFailure,
  claimSolRequest,
  claimSolSuccess,
  claimSolFailure,
  getListPumpTokenRequest,
  getListPumpTokenSuccess,
  getListPumpTokenFailure,
  importPumpTokenRequest,
  importPumpTokenSuccess,
  importPumpTokenFailure,
  getListPumpWalletRequest,
  getListPumpWalletSuccess,
  getListPumpWalletFailure,
  importPumpWalletRequest,
  importPumpWalletSuccess,
  importPumpWalletFailure,
  updateListTraderActive,
  deletePumpWalletRequest,
  deletePumpWalletSuccess,
  deletePumpWalletFailure,
  buyPumpWalletRequest,
  buyPumpWalletSuccess,
  buyPumpWalletFailure,
  sellPumpWalletRequest,
  sellPumpWalletSuccess,
  sellPumpWalletFailure,
  activeWalletRequest,
  activeWalletSuccess,
  activeWalletFailure,
  unActiveWalletRequest,
  unActiveWalletSuccess,
  unActiveWalletFailure,
  getSettingTradeRequest,
  getSettingTradeSuccess,
  getSettingTradeFailure,
  updateSettingTradeRequest,
  updateSettingTradeSuccess,
  updateSettingTradeFailure,
  getProfitTradeRequest,
  getProfitTradeSuccess,
  getProfitTradeFailure,
  deleteBootTxWalletRequest,
  deleteBootTxWalletSuccess,
  deleteBootTxWalletFailure,
} = pumpSlice.actions;

export default pumpSlice.reducer;
