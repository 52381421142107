/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import CommonLoading from '@/components/common/CommonLoading';
import { ErrorMessageLarge } from '@/components/form/input';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Title } from '@/components/login';
import { ImportWalletPopup } from '@/components/token/components/importWalletPopup';
import { NETWORK } from '@/constants';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { mainRequest } from '@/services';
import { checkIsRequesting, getMultiSolBalance } from '@/utils/helper';
import { Box, Link, ThemeProvider, createTheme } from '@mui/material';
import { Connection, PublicKey } from '@solana/web3.js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

interface BootBuySellTxProps {
  type: string;
}
const lightTheme = createTheme({ palette: { mode: 'light' } });

export const PumpBootBuySellTx = (props: BootBuySellTxProps) => {
  const { type } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [listDataSignature, setListDataSignature] = useState<any>([]);
  const [dataWallet, setDataWallet] = useState<any>([]);

  const timeoutIdRef = useRef<any>(null);
  const {
    requestGetListWalletBuyPump,
    requestGetListWalletSellPump,
    requestGetListWalletVolumePump,
    requestPumpUpdateVolumeBuy,
    requestPumpUpdateVolumeSell,
    requestPumpUpdateVolumeVolume,
    requestImportWalletBuy,
    requestImportWalletSell,
    requestImportWalletVolume,
    importWalletBuyPumpStatus,
    importWalletSellPumpStatus,
    importWalletVolumePumpStatus,
    updateVolumeBuyPumpStatus,
    updateVolumeSellPumpStatus,
    updateVolumeVolumePumpStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    requestResetPump,
    deleteBootTxWalletStatus,
  } = usePumpHook();
  const { setErrorMsg, setSuccessMsg, setInfoMsg } = useGlobalHook();
  const queries = useParams();

  if (!NETWORK) {
    throw new Error('REACT_APP_RFC_URL environment variable is not set');
  }
  const connection = new Connection(NETWORK, {
    commitment: 'confirmed',
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
  });

  const startPumpBootTxRequest = async (body: any) => {
    let response;

    // Check the type and execute corresponding API request
    if (type === 'buy') {
      response = await mainRequest(`/pump-wallet/fake-buy`, body, 'post');
    } else if (type === 'sell') {
      response = await mainRequest(`/pump-wallet/fake-sell`, body, 'post');
    } else if (type === 'volume') {
      response = await mainRequest(`/pump-wallet/fake-volume`, body, 'post');
    }
    try {
      const dataTransaction = response?.data?.data || [];
      setListDataSignature(prevDataArray => [
        ...prevDataArray,
        ...dataTransaction,
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const reLoadData = async () => {
    const dataRequestGetListWallet = {
      buy: requestGetListWalletBuyPump,
      sell: requestGetListWalletSellPump,
      volume: requestGetListWalletVolumePump,
    };
    const reloadDataAction = dataRequestGetListWallet[type];
    if (reloadDataAction) {
      await reloadDataAction({ tokenId: queries.id });
    }
  };

  const handleReturnListWallet = () => {
    if (type === 'buy') {
      return listWalletBuy;
    } else if (type === 'sell') {
      return listWalletSell;
    } else {
      return listWalletVolume;
    }
  };
  const dataListWallet = handleReturnListWallet() || [];

  const listWalletAddress = dataListWallet?.map(item => item.address);

  const walletAddresses = listWalletAddress?.map(item => new PublicKey(item));

  const handleClickImportWallet = () => {
    setOpen(true);
  };

  const handleImportWalletBuy = (payload: any) => {
    const body = { ...payload, tokenId: queries.id && +queries.id };
    handleImportWallet(body);
    setOpen(false);
  };

  const handleClickStartStopBootTx = () => {
    const listIdWallet = dataListWallet?.map(item => item.id);
    startPumpBootTxRequest({ walletIds: listIdWallet });
  };

  const setTimeOutStartStopBootTx = () => {
    setIsRunning(!isRunning);
    const isHasVolume = handleCheckHadVolume();
    if (!isHasVolume) {
      const valueVolumeDefault = dataListWallet?.map(item => {
        return {
          walletId: item.id,
          volume: type === 'sell' ? '10' : '0.00001',
        };
      });
      handleUpdateVolume(valueVolumeDefault);
    }
    if (isRunning) {
      clearInterval(timeoutIdRef.current);
      setInfoMsg(['Stopped boot tx!']);
    } else {
      setInfoMsg(['Started boot tx!']);
      timeoutIdRef.current = setInterval(() => {
        handleClickStartStopBootTx();
      }, 1000);
    }
  };

  const handleSaveWallet = value => {
    const valueHandle = dataListWallet?.map(item => {
      const keyVolume = `volume${item.id}`;
      if (value.hasOwnProperty(keyVolume) && value[keyVolume]) {
        return { walletId: item.id, volume: String(value[keyVolume]) };
      } else {
        return type === 'sell'
          ? { walletId: item.id, volume: '10' }
          : { walletId: item.id, volume: '0.00001' };
      }
    });
    handleUpdateVolume({ volumes: valueHandle });
  };

  const handleCheckHadVolume = () => {
    const value = handleReturnListWallet();
    return value?.some(obj => 'volume' in obj);
  };

  const handleUpdateVolume = data => {
    const updateVolumeMap = {
      buy: requestPumpUpdateVolumeBuy,
      sell: requestPumpUpdateVolumeSell,
      volume: requestPumpUpdateVolumeVolume,
    };

    const updateVolumeAction = updateVolumeMap[type];
    if (updateVolumeAction) {
      updateVolumeAction(data);
    }
  };

  const handleImportWallet = body => {
    const importWalletMap = {
      buy: requestImportWalletBuy,
      sell: requestImportWalletSell,
      volume: requestImportWalletVolume,
    };

    const importWalletAction = importWalletMap[type];
    if (importWalletAction) {
      importWalletAction(body);
    }
  };

  const loading = useMemo(
    () =>
      checkIsRequesting([
        importWalletBuyPumpStatus,
        importWalletSellPumpStatus,
        importWalletVolumePumpStatus,
        updateVolumeBuyPumpStatus,
        updateVolumeSellPumpStatus,
        updateVolumeVolumePumpStatus,
      ]),
    [
      importWalletBuyPumpStatus,
      importWalletSellPumpStatus,
      importWalletVolumePumpStatus,
      updateVolumeBuyPumpStatus,
      updateVolumeSellPumpStatus,
      updateVolumeVolumePumpStatus,
    ],
  );

  const setDataBalances = async () => {
    // Fetch SOL balances
    const solBalances = await getMultiSolBalance(connection, walletAddresses);

    // Merge balances into dataListWallet
    const result = dataListWallet?.map((item, index) => ({
      ...item,
      solBalance: solBalances[index],
    }));

    setDataWallet(result);
  };

  useEffect(() => {
    setDataBalances();
  }, [dataListWallet]);

  useEffect(() => {
    const statusMap = {
      buy: importWalletBuyPumpStatus,
      sell: importWalletSellPumpStatus,
      volume: importWalletVolumePumpStatus,
    };

    const currentStatus = statusMap[type];

    if (currentStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Import wallet success!')]);
      reLoadData();
    } else if (currentStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to import wallet')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    importWalletBuyPumpStatus,
    importWalletSellPumpStatus,
    importWalletVolumePumpStatus,
  ]);

  useEffect(() => {
    const statusMap = {
      buy: updateVolumeBuyPumpStatus,
      sell: updateVolumeSellPumpStatus,
      volume: updateVolumeVolumePumpStatus,
    };

    const currentStatus = statusMap[type];

    if (currentStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Update volume success!')]);
      reLoadData();
    } else if (currentStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to update volume')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    updateVolumeBuyPumpStatus,
    updateVolumeSellPumpStatus,
    updateVolumeVolumePumpStatus,
  ]);

  useEffect(() => {
    setTimeout(() => {
      reLoadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  useEffect(() => {
    const handleListWallet = handleReturnListWallet();
    handleListWallet.forEach((item: any) => {
      setValue(`volume${item.id}`, item?.volume);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWalletBuy, listWalletSell, listWalletVolume]);

  useEffect(() => {
    if (deleteBootTxWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Please check again!')]);
    }
    if (deleteBootTxWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Wallet is imported!')]);
      reLoadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBootTxWalletStatus]);

  useEffect(() => {
    return () => requestResetPump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonContainer className="top">
      <Box component="form">
        <Box
          component="div"
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Title>Start/stop Boot tx</Title>
          <CustomButton
            onClick={setTimeOutStartStopBootTx}
            buttonType={CustomButtonType.NO_ICON}
            title={isRunning ? 'Stop Boot tx' : 'Start Boot tx'}
            className="multi_color_btn"
            disabled={dataListWallet?.length === 0}
          />
          {isRunning && (
            <div className="meter animate">
              <span style={{ width: '100%' }}>
                <span>Boot Tx is running</span>
              </span>
            </div>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '20px',
            marginBottom: '20px',
          }}
        >
          <CustomButton
            onClick={handleClickImportWallet}
            buttonType={CustomButtonType.NO_ICON}
            title={'Import wallet'}
            className="multi_color_btn"
          />
          <CustomButton
            buttonType={CustomButtonType.NO_ICON}
            title={'Update Volume'}
            className="multi_color_btn main_btn"
            onClick={handleSubmit(handleSaveWallet)}
            disabled={dataListWallet?.length === 0}
          />
        </Box>

        <Box>
          <CommonTable
            type={TableType.BOOT_BUY_SELL_TX}
            data={dataWallet}
            control={control}
          />
        </Box>
        {Object.keys(errors).length > 0 && (
          <ErrorMessageLarge>*Volume cannot be empty</ErrorMessageLarge>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            border: '1px solid #333',
            borderRadius: 1,
            backgroundColor: '#f0f0f0',
            marginTop: '32px',
          }}
        >
          <Title>Transaction</Title>
          {[lightTheme].map((theme, index) => (
            <Box
              sx={{
                maxHeight: '400px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f0f0f0',
                  height: 400,
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10px',
                  backgroundColor: '#555',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {listDataSignature.map(item => (
                    <Box
                      sx={{
                        flex: 1,
                        width: '100%',
                        wordWrap: 'break-word',
                        paddingLeft: '12px',
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        href={`https://solscan.io/tx/${item.signature}`}
                        target="_blank"
                        sx={{
                          wordWrap: 'break-word',
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          fontSize: '0.8rem',
                          lineHeight: '1.43',
                          letterSpacing: '0.01071em',
                          '&:hover': {
                            color: '#0d47a1',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {item.signature}
                      </Link>
                    </Box>
                  ))}
                  {listDataSignature.length === 0 && (
                    <Box
                      sx={{
                        flex: 1,
                        width: '100%',
                        wordWrap: 'break-word',
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        fontSize: '0.9rem',
                        lineHeight: '1.43',
                        letterSpacing: '0.01071em',
                        paddingLeft: '12px',
                        textAlign: 'center',
                      }}
                    >
                      {isRunning ? 'Loading...' : 'No data'}
                    </Box>
                  )}
                </Box>
              </ThemeProvider>
            </Box>
          ))}
        </Box>
      </Box>
      <ImportWalletPopup
        open={open}
        onSubmit={handleImportWalletBuy}
        onClose={() => setOpen(false)}
      />
      <CommonLoading show={loading} />
    </CommonContainer>
  );
};
