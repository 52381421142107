import { DownIcon } from '@/components/icons';
import { UpIcon } from '@/components/icons/IconUp';
import {
  IconWrapper,
  ItemName,
  ItemWrapper,
  MenuItem,
} from '@/components/layouts/sidebar/components';
import { SubMenuItemProps } from '@/types/layout';
import { Box, Collapse } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface SidebarItemProps {
  name: string;
  icon: ReactNode;
  to: string;
  items?: SubMenuItemProps[];
  openAddFloor?: (val: boolean) => void;
  className?: string;
  isActive?: boolean;
}

export const SidebarItem = ({
  name,
  icon,
  to,
  items,
  openAddFloor,
  className = '',
  isActive,
}: SidebarItemProps) => {
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(true);
  const [expanded, setExpanded] = useState(isActive ?? false);
  const [isSelected, setIsSelected] = useState('');

  const handleClick = () => {
    setOpen(!open);
    setExpanded(!expanded);
  };

  const active = useMemo(() => {
    if (items) {
      return location.pathname.includes(to);
    }
    // if (to === '/trader') {
    //   return true;
    // }

    return to === location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, to]);

  const handleSelect = (name: string): boolean => {
    const value = localStorage.getItem('rootPath');
    if (value) {
      return value === name;
    }
    return false;
  };

  useEffect(() => {
    const value = localStorage.getItem('rootPath');
    if (value && !to) {
      if (location.pathname.includes(value)) {
        setIsSelected(value);
      }
      localStorage.removeItem('rootPath');
      return;
    }
  }, [location.pathname]);

  return (
    <MenuItem>
      {items ? (
        <>
          <span
            className="icon_wrapper"
            onClick={handleClick}
            style={{ display: 'flex' }}
          >
            <ItemWrapper to={''} selected={false} active="false">
              <IconWrapper className={className}>{icon}</IconWrapper>
              <ItemName>{name}</ItemName>
            </ItemWrapper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {expanded ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                >
                  <UpIcon />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                >
                  <DownIcon />
                </Box>
              )}
            </Box>
          </span>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            sx={{ marginTop: '8px' }}
          >
            {items.map(item => {
              return (
                <Box
                  key={item.to}
                  sx={{ paddingLeft: '24px', marginBottom: '6px' }}
                >
                  <ItemWrapper
                    to={item.to}
                    selected={
                      handleSelect(item.name) || item.to === location.pathname
                    }
                    active="true"
                  >
                    <IconWrapper className={className}>{item.icon}</IconWrapper>
                    <ItemName>{item.name}</ItemName>
                  </ItemWrapper>
                </Box>
              );
            })}
          </Collapse>
        </>
      ) : (
        <ItemWrapper
          to={to}
          selected={handleSelect(name) || active}
          active="true"
        >
          <IconWrapper className={className}>{icon}</IconWrapper>
          <ItemName>{name}</ItemName>
        </ItemWrapper>
      )}
    </MenuItem>
  );
};
