export const CameraIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
  >
    <path
      d="M12.383 1.19331C12.193 0.965319 11.9116 0.833496 11.6148 0.833496H8.38488C8.0881 0.833496 7.80665 0.965319 7.61666 1.19331L5.83317 3.3335H3.33317C2.89114 3.3335 2.46722 3.50909 2.15466 3.82165C1.8421 4.13421 1.6665 4.55814 1.6665 5.00016V12.5002C1.6665 12.9422 1.8421 13.3661 2.15466 13.6787C2.46722 13.9912 2.89114 14.1668 3.33317 14.1668H16.6665C17.1085 14.1668 17.5325 13.9912 17.845 13.6787C18.1576 13.3661 18.3332 12.9422 18.3332 12.5002V5.00016C18.3332 4.55814 18.1576 4.13421 17.845 3.82165C17.5325 3.50909 17.1085 3.3335 16.6665 3.3335H14.1665L12.383 1.19331Z"
      stroke="#555C6A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10.8335C11.3807 10.8335 12.5 9.71421 12.5 8.3335C12.5 6.95278 11.3807 5.8335 10 5.8335C8.61929 5.8335 7.5 6.95278 7.5 8.3335C7.5 9.71421 8.61929 10.8335 10 10.8335Z"
      stroke="#555C6A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
