import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { Title } from '@/components/login';
import { EDialogTypes } from '@/enums/holder';
import { RequestStatus } from '@/constants/API';
import { IHolderDialogProps } from '@/interfaces/holder';
import { CommonTable } from '@/components/layouts/table';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { TableType } from '@/components/layouts/table/table';
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import HolderPopups from '@/components/token/components/HolderPopups';
import { getHolderTokenDetailRequest } from '@/stores/slices/token/token';

const HolderTokenDetailPage = () => {
  const { id = 0 } = useParams();
  const dispatch = useAppDispatch();
  const {
    getHolderTokenDetailStatus,
    importHolderWalletStatus,
    holderTokenDetail,
  } = useAppSelector(state => state.token);
  const [holderDialogs, setHolderDialogs] = useState<
    Omit<IHolderDialogProps, 'onClose'>
  >({
    isOpen: false,
    dialogType: null,
  });

  const onPageChange = (newPage: number) => {
    const newParams = { ...holderTokenDetail.params, page: newPage };
    dispatch(
      getHolderTokenDetailRequest({
        id: +id,
        params: newParams,
      }),
    );
  };

  const onOpenDialog = (dialogType: IHolderDialogProps['dialogType']) => {
    setHolderDialogs({
      ...holderDialogs,
      isOpen: true,
      dialogType,
    });
  };

  const onCloseDialog = () => {
    setHolderDialogs({
      ...holderDialogs,
      isOpen: false,
      dialogType: null,
    });
  };

  useEffect(() => {
    dispatch(
      getHolderTokenDetailRequest({
        id: +id,
        params: holderTokenDetail.params,
      }),
    );
  }, []);

  return (
    <CommonContainer
      className="top"
      sx={{
        position: 'relative',
      }}
    >
      <Title>Holder token detail</Title>
      <Stack
        sx={{
          width: '100%',
          my: 2,
        }}
        spacing={2}
        direction="row"
        justifyContent="end"
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={() => onOpenDialog(EDialogTypes.IMPORT_HOLDER_WALLET)}
        >
          Import wallet
        </button>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          my: 2,
        }}
        spacing={2}
        direction="row"
        justifyContent="center"
      >
        <Box
          sx={{
            px: 2,
            py: 1,
            backgroundColor: '#2c2d30',
            color: '#fff',
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 1,
          }}
        >
          Total hold: {holderTokenDetail?.meta?.totalPercentage ?? 0}%
        </Box>
        <Box
          sx={{
            px: 2,
            py: 1,
            backgroundColor: '#2c2d30',
            color: '#fff',
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 1,
          }}
        >
          Total SOL: {holderTokenDetail?.totalSol ?? '0.0000'}
        </Box>
      </Stack>

      <CommonTable
        type={TableType.HOLDER_TOKEN_DETAIL}
        data={holderTokenDetail.data ?? []}
        pagination={{
          currentPage: holderTokenDetail?.meta?.currentPage ?? 1,
          totalPages: holderTokenDetail?.meta?.totalPages ?? 1,
          onPageChange,
        }}
      />

      <HolderPopups
        isOpen={holderDialogs.isOpen}
        dialogType={holderDialogs.dialogType}
        onClose={onCloseDialog}
      />
      <CommonLoading
        show={[getHolderTokenDetailStatus, importHolderWalletStatus].includes(
          RequestStatus.REQUESTING,
        )}
      />
    </CommonContainer>
  );
};

export default HolderTokenDetailPage;
