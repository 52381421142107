/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import CommonLoading from '@/components/common/CommonLoading';
import { Loader } from '@/components/common/loader';
import CustomDatePicker from '@/components/form/datepicker';
import Input, { Label } from '@/components/form/input';
import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import { Title } from '@/components/login';
import {
  Container,
  JumboTron,
  PriceTag,
  TwoFormItem,
} from '@/components/token/components';
import { CREATE_MARKET_TYPE } from '@/components/token/components/pool';
import { RequestStatus } from '@/constants/API';
import Path from '@/constants/path';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface WalletType {
  address: string;
  privateKey: string;
}
export const CreatePoolPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createType, setCreateType] = useState<CREATE_MARKET_TYPE>(
    CREATE_MARKET_TYPE.NEW,
  );
  // const [isAdvanced, setAdvance] = useState<boolean>(false);

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { requestResetToken, sendSolStatus } = useTokenHook();

  const loading = useMemo(
    () => checkIsRequesting([sendSolStatus]),
    [sendSolStatus],
  );

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sendSolStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Sol transfered!')]);
    }

    if (sendSolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to transfrer sol')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSolStatus]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    baseToken: string;
    quoteToken: string;
    marketId: string;
    startTime: string;
    baseTokenLiquidity: number;
    quoteTokenLiquidity: number;
  }>({
    defaultValues: {
      quoteToken: 'So11111111111111111111111111111111111111112',
    },
  });

  const submitForm = async (formDt: any) => {};

  return (
    <Container className="big more">
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <Box
          component="div"
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            '& hr': {
              marginBottom: '30px',
            },
            '& .MuiAlert-root': {
              background: '#A43399',
            },
            '& .MuiAlert-icon, & .MuiAlert-message': {
              color: '#fff',
            },
          }}
        >
          <Title className="smal">Create pool</Title>
          <JumboTron mt={2} className="full">
            <TwoFormItem className="gap">
              <InputContainer className="no_mb">
                <Label>
                  <span>*</span>
                  Base token
                </Label>
                <Controller
                  control={control}
                  name="baseToken"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <Input
                        label={'Base Token'}
                        value={value}
                        onChange={onChange}
                        isRequired
                        error={errors?.baseToken?.message}
                        placeholder={'Base Token'}
                      />
                    );
                  }}
                />
              </InputContainer>
              <InputContainer className="no_mb">
                <Label>
                  <span>*</span>
                  Quote token
                </Label>
                <Controller
                  control={control}
                  name="quoteToken"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <Input
                        label={'Quote Token'}
                        value={value}
                        onChange={onChange}
                        isRequired
                        error={errors?.quoteToken?.message}
                        placeholder={'Quote Token'}
                      />
                    );
                  }}
                />
              </InputContainer>
            </TwoFormItem>
            <TwoFormItem className="gap">
              <InputContainer className="no_mb">
                <Label>
                  <span>*</span>
                  Base Token Initial Liquidity
                </Label>
                <Controller
                  control={control}
                  name="baseTokenLiquidity"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <NumberInput
                        label={'Base Token Initial Liquidity'}
                        value={value}
                        onChange={onChange}
                        isRequired
                        error={errors?.baseTokenLiquidity?.message}
                        placeholder="Base Token Initial Liquidity"
                      />
                    );
                  }}
                />
              </InputContainer>

              <InputContainer className="no_mb">
                <Label>
                  <span>*</span>
                  Quote Token Initial Liquidity
                </Label>
                <Controller
                  control={control}
                  name="quoteTokenLiquidity"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <NumberInput
                        label={'Quote Token Initial Liquidity'}
                        value={value}
                        onChange={onChange}
                        isRequired
                        error={errors?.quoteTokenLiquidity?.message}
                        placeholder="Quote Token Initial Liquidity"
                      />
                    );
                  }}
                />
              </InputContainer>
            </TwoFormItem>

            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                  '&  .MuiSwitch-switchBase.Mui-checked': {
                    color: '#A43399',
                  },
                }}
              >
                <Label>
                  <span>*</span>
                  MARKET ID (Only one liquidity pool can be created for a market
                  ID)
                </Label>
                <button
                  className={`button  main_button logout ${
                    createType === CREATE_MARKET_TYPE.NEW
                      ? 'create_new'
                      : 'white_border'
                  }`}
                  onClick={() => navigate(Path.CREATE_BOOK)}
                >
                  Create new market
                </button>
              </Box>
              <InputContainer className="no_mb">
                <Controller
                  control={control}
                  name="marketId"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <Input
                        label={''}
                        value={value}
                        onChange={onChange}
                        error={errors?.marketId?.message}
                        placeholder={'Market ID '}
                      />
                    );
                  }}
                />
              </InputContainer>
            </Box>
            <TwoFormItem className="gap">
              <InputContainer className="no_mb">
                <Controller
                  control={control}
                  name="startTime"
                  rules={{
                    required: t('This field is required'),
                  }}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <CustomDatePicker
                        label={'Start time (Optional)'}
                        value={value}
                        onChange={onChange}
                        isRequired
                      />
                    );
                  }}
                />
              </InputContainer>
              <Box>
                <Label>Price</Label>
                <Box>
                  <PriceTag>1 Quote = 1 Base</PriceTag>
                  <br />
                  <PriceTag>1 Base = 1 Quote</PriceTag>
                </Box>
              </Box>
            </TwoFormItem>
          </JumboTron>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <CustomButton
            type="submit"
            buttonType={CustomButtonType.NO_ICON}
            title={'Create Liquidity AMM Pool'}
            className="multi_color_btn main_btn"
          />
        </Box>
      </Box>
      <CommonLoading show={loading} />
    </Container>
  );
};
