import React from 'react';

const BybitIcon: React.FC = () => {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128 32C74.9807 32 32 74.9807 32 128V384C32 437.019 74.9807 480 128 480H384C437.019 480 480 437.019 480 384V128C480 74.9807 437.019 32 384 32H128ZM176 108C156.118 108 140 124.118 140 144V368C140 387.882 156.118 404 176 404H320C357.555 404 388 373.555 388 336V304C388 271.022 364.525 243.528 333.374 237.315C337.622 228.438 340 218.497 340 208V176C340 138.445 309.555 108 272 108H176ZM300 208C300 223.464 287.464 236 272 236H180V148H272C287.464 148 300 160.536 300 176V208ZM180 364V276H320C335.464 276 348 288.536 348 304V336C348 351.464 335.464 364 320 364H180Z"
        fill="black"
      />
    </svg>
  );
};

export default BybitIcon;
