/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import { ImageInput } from '@/components/form/imageUploading';
import Input from '@/components/form/input';
import { InputName } from '@/components/form/inputName';
import NumberInput from '@/components/form/numberInput';
import SelectItem from '@/components/form/select';
import { InputContainer } from '@/components/form/styled';
import TextArea from '@/components/form/textarea';
import { CloseIcon, PlusIcon } from '@/components/icons';
import { AppConfig } from '@/config';
import { TOKEN_ACCESS_KEY } from '@/constants';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { PumpfunTokenForm } from '@/stores/slices/token/type';
import { getAddressFromPrivateKey, validateKey } from '@/utils/helper';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Import the NFTStorage class and File constructor from the 'nft.storage' package

export interface PumpfunFormProps {
  onCancel?: () => void;
  onSubmit?: (data: FormData) => void;
  setValuePumpfunMint?: any;
}

export const PumpfunForm = ({
  onCancel,
  onSubmit,
  setValuePumpfunMint,
}: PumpfunFormProps) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<string | undefined>('');
  const [validErrorValue, setValidErrorValue] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PumpfunTokenForm>({
    defaultValues: {
      decimal: 6,
      supply: 1000000000,
      buys: [{ buyPrivateKey: '', amount: '' }],
    },
  });

  const watchValuePrivateKey = watch('ownerPrivateKey');
  const isValidKey = watchValuePrivateKey && validateKey(watchValuePrivateKey);

  const { setErrorMsg } = useGlobalHook();
  const { requestUpdateTokenImage, contractAddress } = useTokenHook();
  const {
    fields,
    append: addOneItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'buys',
  });

  const watchValueContractAddress = watch('address');

  const submitForm = async (formDt: any) => {
    requestUpdateTokenImage(RequestStatus.REQUESTING);

    try {
      let formData = new FormData();
      formData.append('image', formDt.image);
      formData.append('name', formDt.name);
      formData.append('symbol', formDt.symbol);
      formData.append('description', formDt.description);
      if (formDt.twitter) {
        formData.append('twitter', formDt.twitter);
      }
      if (formDt.website) {
        formData.append('website', formDt.website);
      }
      if (formDt.telegram) {
        formData.append('telegram', formDt.telegram);
      }

      let request = await fetch(`${AppConfig.apiBase}/pumpfun/medatdata`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_ACCESS_KEY)}`,
        },
        body: formData,
      });
      const res = await request.json();
      formDt.uri = res.data?.metadataUri;
      formDt.image = res.data?.metadata?.image;
      requestUpdateTokenImage(RequestStatus.SUCCESS);
    } catch (error) {
      requestUpdateTokenImage(RequestStatus.SUCCESS);
      setErrorMsg(['Something went wrong. Unable to upload your token image']);
      return;
    }

    formDt.decimal = +formDt.decimal;
    formDt.supply = formDt.supply?.toString().replace(/,/g, '');

    onSubmit &&
      onSubmit({
        ...formDt,
      });
  };

  const uploadImage = async (img: any) => {
    requestUpdateTokenImage(RequestStatus.REQUESTING);
    const fileReader = new FileReader();
    try {
      fileReader.onload = function () {
        if (fileReader.result) {
          const blob = new Blob([fileReader.result]);
          setValue('image', blob);
        }
      };

      fileReader.readAsArrayBuffer(img[0].file);
      requestUpdateTokenImage(RequestStatus.SUCCESS);
    } catch (error) {
      setErrorMsg(['Something went wrong. Unable to upload your token image']);
      requestUpdateTokenImage(RequestStatus.SUCCESS);
    }
  };

  const handleOptionContractAddress: any = () => {
    if (contractAddress) {
      const valueContractAddress = contractAddress.map(item => ({
        label: item.address,
        value: item.address,
      }));
      return [{ label: 'No select', value: '' }, ...valueContractAddress];
    }
  };

  useEffect(() => {
    setValuePumpfunMint(watchValueContractAddress);
  }, [watchValueContractAddress]);

  useEffect(() => {
    const address =
      watchValuePrivateKey && getAddressFromPrivateKey(watchValuePrivateKey);
    if (watchValuePrivateKey) {
      if (isValidKey) {
        setValidErrorValue('');
      } else {
        setValidErrorValue('Private key is not valid');
      }
    }
    setAddress(address);
  }, [watchValuePrivateKey]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submitForm)}
      style={{ marginTop: 50 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="ownerPrivateKey"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Owner Private Key')}
                    isRequired
                    error={validErrorValue || errors?.ownerPrivateKey?.message}
                    value={value}
                    onChange={onChange}
                    type={'password'}
                    addressWallet={address}
                  />
                );
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputName>Contract Address (Optional)</InputName>
            <Controller
              control={control}
              name="address"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <SelectItem
                    sx={{
                      border: '1px solid var(--black) !important',
                      borderRadius: '4px !important',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '23px',
                    }}
                    options={handleOptionContractAddress()}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <Box
            component="div"
            mb={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <>
              {/* <Title>Token Image</Title> */}
              <Controller
                control={control}
                name="image"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value } = field;
                  return (
                    <ImageInput
                      label={t('Token Image')}
                      title={t('Drag an image here or click “Upload” below')}
                      width={120}
                      height={120}
                      onUpload={uploadImage}
                      value={value ? [value] : []}
                      isRequired
                      errorMessage={
                        errors.image ? 'This field is required' : ''
                      }
                    />
                  );
                }}
              />
            </>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer>
            <Controller
              control={control}
              name="name"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Name')}
                    isRequired
                    error={errors?.name?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="symbol"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Symbol')}
                    isRequired
                    error={errors?.symbol?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
      </Grid>
      <InputContainer className="single">
        <Controller
          control={control}
          name="description"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <TextArea
                label={t('Description')}
                error={errors?.description?.message}
                value={value}
                onChange={onChange}
                rows={3}
              />
            );
          }}
        />
      </InputContainer>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="decimal"
              rules={{
                required: t('This field is required'),
                pattern: {
                  value: /^[1-9]$/,
                  message: 'Decimal is between 1 and 9',
                },
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <NumberInput
                    label={'Decimal'}
                    value={value}
                    onChange={onChange}
                    isRequired
                    error={errors?.decimal?.message}
                    placeholder="Decimal"
                    disabled
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="supply"
              rules={{
                required: t('This field is required'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <NumberInput
                    label={'Supply'}
                    value={value}
                    onChange={onChange}
                    isRequired
                    error={errors?.supply?.message}
                    placeholder="Supply"
                    disabled
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
      </Grid>

      <InputContainer className="mt1 ">
        <Controller
          control={control}
          name="website"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Input
                label={t('Website (Optional)')}
                error={errors?.website?.message}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </InputContainer>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputContainer className="mt1 ">
            <Controller
              control={control}
              name="twitter"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Twitter (Optional)')}
                    error={errors?.twitter?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer className="mt1">
            <Controller
              control={control}
              name="telegram"
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Telegram (Optional)')}
                    error={errors?.telegram?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </InputContainer>
        </Grid>
      </Grid>
      {fields?.map((it, id) => (
        <Box
          key={it.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
            position: 'relative',
            marginBottom: '15px',
            '& > .custom_input > div': {
              marginBottom: '10px !important',
            },
            '& > .custom_input:first-child': {
              width: '80%',
            },
            '& > .custom_input:nth-child(2)': {
              width: 'calc(20% - 10px)',
            },
          }}
        >
          <Box className="custom_input">
            <Controller
              control={control}
              name={`buys.${id}.buyPrivateKey` as const}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Input
                    label={t('Buy private key')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Box>
          <Box className="custom_input">
            <Controller
              control={control}
              name={`buys.${id}.amount` as const}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <NumberInput
                    label={'Buy Amount'}
                    value={value}
                    onChange={onChange}
                    placeholder="Buy Amount"
                  />
                );
              }}
            />
          </Box>
          {fields.length - 1 === id && fields.length < 20 ? (
            <button
              className={`button  main_button logout special`}
              onClick={() =>
                addOneItem({
                  buyPrivateKey: '',
                  amount: '',
                })
              }
            >
              <PlusIcon />
            </button>
          ) : (
            <Box
              sx={{ position: 'absolute', right: '-30px', top: '32px' }}
              onClick={() => removeItem(id)}
            >
              <CloseIcon />
            </Box>
          )}
        </Box>
      ))}
      <InputContainer className="mt1">
        <Controller
          control={control}
          name="devAmount"
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <NumberInput
                label={'Dev buy volume'}
                value={value}
                onChange={onChange}
                error={errors?.devAmount?.message}
                placeholder="Dev buy volume"
              />
            );
          }}
        />
      </InputContainer>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title={`Create Pumpfun`}
          className="multi_color_btn main_btn"
        />
      </Box>
    </Box>
  );
};
