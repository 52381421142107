import { Container, JumboTron } from '@/components/token/components';
import PumpfunBundleForm from '@/components/token/components/PumpfunBundleForm';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { Alert } from '@mui/material';
import { isUndefined, pickBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { pumpfunBundleRequest } from '@/stores/slices/token/token';
import { IPumpfunBundleRequest } from '@/interfaces/holder';

const PumpfunBundle = () => {
  const dispatch = useAppDispatch();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    requestResetToken,
    createPumpFunStatus,
    requestCreatePumpFun,
    uploadTokenImageStatus,
    pumpfun,
    getPumpfunMintStatus,
    requestContractAddress,
  } = useTokenHook();

  const [valuePumpfunMint, setValuePumpfunMint] = useState<string>('');

  useEffect(() => {
    if (createPumpFunStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Pumpfun token created!']);
    }

    if (createPumpFunStatus === RequestStatus.ERROR) {
      setErrorMsg(['Something went wrong. Unable to create Pumpfun']);
    }
  }, [createPumpFunStatus]);

  const onSubmit = (formDt: any) => {
    const { buys: snipers } = formDt;
    const buys = snipers
      .filter((sniper: any) => sniper.buyPrivateKey && sniper.amount)
      .map((sniper: any) => ({
        buyPrivateKey: sniper.buyPrivateKey,
        amount: +sniper.amount?.replace(/,/g, ''),
      }));

    const pumpfunMint = formDt.address;
    delete formDt.address;

    const formValues = {
      ...formDt,
      buys,
      mint: pumpfunMint,
      devAmount: +formDt.devAmount?.replace(/,/g, ''),
    };

    const submitData = pickBy(formValues, value => !isUndefined(value));

    delete submitData['supply'];
    delete submitData['decimal'];

    dispatch(pumpfunBundleRequest(submitData as IPumpfunBundleRequest));
  };

  return (
    <Container className="big">
      {valuePumpfunMint ? (
        <JumboTron>
          <Alert sx={{ color: '#A43399', fontSize: '18px' }}>
            CA: {valuePumpfunMint}
          </Alert>
        </JumboTron>
      ) : (
        ''
      )}

      {pumpfun?.mint ? (
        <Alert
          severity="success"
          sx={{
            margin: '10px 0 30px',
            '& a': {
              color: '#fff',
            },
            '& span': {
              color: '#fff',
              paddingRight: '5px',
            },
          }}
        >
          <span>Success create token: {pumpfun?.mint} at tx</span>
          <a
            href={`https://solscan.io/tx/${pumpfun?.tx}`}
            target="_blank"
            rel="noreferrer"
          >{`https://solscan.io/tx/${pumpfun?.tx}`}</a>
        </Alert>
      ) : (
        ''
      )}
      <PumpfunBundleForm
        onSubmit={onSubmit}
        setValuePumpfunMint={setValuePumpfunMint}
      />
    </Container>
  );
};

export default PumpfunBundle;
