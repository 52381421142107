/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import { ConfigTraderPopup } from '@/components/layouts/pumpfun/configTraderPopup';
import { PumpfunCard } from '@/components/layouts/pumpfun/pumpfunTraderCard';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Title } from '@/components/login';
import { ConfirmDeletePopup } from '@/components/token/components/confirmDeletePopup';
import { ImportWalletPopup } from '@/components/token/components/importWalletPopup';
import { NETWORK } from '@/constants';
import { RequestStatus, StatusCode } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { mainRequest } from '@/services';
import {
  calculatePNL,
  getTraderProfits,
  getSolBalances,
  getWalletsInfo,
  getTraderTokenBalances,
  sleep,
} from '@/utils/helper';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Grid,
  Link,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { AccountLayout } from '@solana/spl-token';
import { Connection, PublicKey } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { chunk, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
export interface DeleteForm {
  input: string;
}

const lightTheme = createTheme({ palette: { mode: 'light' } });
let walletController = new AbortController();

export enum TypeTrader {
  BUY = 'buy',
  SELL = 'sell',
}
export const PumpfunTraderDetail = () => {
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { t } = useTranslation();
  const [isExecuted, setIsExecuted] = useState<boolean>(false);
  const [open, setOpen] = useState({
    importWallet: false,
    deleteAll: false,
    walletConfig: false,
    traderConfig: false,
  });
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [listDataSignature, setListDataSignature] = useState<any>([]);
  const [dataListWallet, setDataListWallet] = useState<any[]>([]);
  const queries = useParams();
  const { id } = queries;

  if (!NETWORK) {
    throw new Error('REACT_APP_RFC_URL environment variable is not set');
  }
  const connection = new Connection(NETWORK, {
    commitment: 'confirmed',
  });

  const {
    requestImportPumpWallet,
    requestDeletePumpWallet,
    importPumpWalletStatus,
    deletePumpWalletStatus,
    buyPumpWalletStatus,
    sellPumpWalletStatus,
    activeWalletStatus,
    unActiveWalletStatus,
    requestUpdateSettingTrade,
    requestResetPump,
  } = usePumpHook();

  const getTokenDetail = async (id?: string) => {
    try {
      const { data } = await mainRequest(`/trader/token/${id}`, {}, 'get');
      localStorage.setItem('token_detail', JSON.stringify(data.data));
    } catch {
      throw new Error('Get token detail failed');
    }
  };

  const getSolsHandler = async (walletPublicAddresses: PublicKey[]) => {
    const solPromises = getSolBalances(connection, walletPublicAddresses);
    const solBalancesRes = await Promise.all(solPromises);
    const sols = solBalancesRes.flatMap(item =>
      item.flatMap(subItem => (subItem ? subItem.lamports : 0)),
    );

    return { sols };
  };

  const getTokenAndProfitChunk = async (
    chunkWallet: any[],
    tokenInfo: any,
    listWallets: any[],
    isFirstIterator = false,
  ) => {
    let tokenAndProfitRes: any[] = [];
    const { walletPublicKeys, walletIds } = getWalletsInfo(chunkWallet);
    try {
      setIsExecuted(isFirstIterator);
      !isFirstIterator && (await sleep(1000));
      const tokenRes: any[] = await Promise.all(
        getTraderTokenBalances(
          connection,
          walletPublicKeys,
          tokenInfo.contractAddress,
        ),
      );

      const profitRes = await getTraderProfits({
        walletIds: walletIds,
        tokenId: id,
      });
      const tokenBalances = tokenRes.flatMap((token: any) => {
        if (isEmpty(token.value)) {
          return 0;
        }
        const accountData = AccountLayout.decode(
          new Uint8Array(token.value[0]?.account?.data),
        );

        return `${accountData.amount}`;
      });

      const { data } = profitRes;
      const traderProfits = data.data?.profit?.map((profit: any, index) => {
        let worth = 0;
        if (data.solPrice > 0) {
          worth = new BigNumber(tokenBalances[index] ?? 0)
            .div(new BigNumber(10).pow(data?.decimal ?? 0))
            .multipliedBy(Number(data?.tokenPrice) || 0)
            .div(data?.solPrice)
            .toNumber();
        }
        return calculatePNL(profit.initial, worth);
      });
      tokenAndProfitRes = walletIds.map((id, index) => ({
        id,
        tokenBalance: tokenBalances[index],
        traderProfit: traderProfits[index],
      }));
    } catch (error) {
      throw new Error('Get token & profit failed');
    } finally {
      setIsExecuted(false);
    }

    const result = listWallets.map(wallet => {
      if (!walletIds.includes(wallet.id)) {
        return wallet;
      }
      const updateWallet = tokenAndProfitRes.find(
        item => item.id === wallet.id,
      );
      return {
        ...wallet,
        ...updateWallet,
      };
    });
    setDataListWallet(result);
  };

  const getTokenAndProfitHandler = async (listWallets: any[]) => {
    const chunkedWallets = chunk(listWallets, 5);
    const tokenInfo = JSON.parse(localStorage.getItem('token_detail') || '{}');

    chunkedWallets.forEach((chunkWallet, index) => {
      getTokenAndProfitChunk(chunkWallet, tokenInfo, listWallets, index === 0);
    });
  };

  const reloadData = () => {
    refreshWallets();
  };

  const handleImportWalletBuy = (payload: any) => {
    requestImportPumpWallet(payload);
    setOpen({ ...open, importWallet: false });
  };

  const handleDeleteAll = () => {
    const ids = dataListWallet.map(item => item.id);
    requestDeletePumpWallet({ walletIds: ids });
    setOpen({ ...open, deleteAll: false });
  };

  const handleTraderConfig = (data: any) => {
    requestUpdateSettingTrade({
      slipBuyPercent: data.slipBuyPercent,
      slipSellPercent: data.slipSellPercent,
      gasBuy: data.gasBuy,
      gasSell: data.gasSell,
      tipMEV: data.tipMEV,
      antiMEV: data.antiMEV,
    });
    setOpen({ ...open, traderConfig: false });
  };

  const handleSubmitBuyAndSell = async (
    data: any,
    value: string,
    type: TypeTrader,
  ) => {
    const ids = dataListWallet.map(item => item.id);

    let nameAPI;
    let body;

    if (type === TypeTrader.BUY) {
      //api buy
      body = {
        tokenId: queries.id && +queries.id,
        walletIds: ids,
        amount: value,
      };
      nameAPI = '/trader/buy';
    }
    if (type === TypeTrader.SELL) {
      //api sell
      body = {
        tokenId: queries.id && +queries.id,
        walletIds: ids,
        percent: value,
      };
      nameAPI = '/trader/sell';
    }
    const response = await mainRequest(`${nameAPI}`, body, 'post');

    try {
      setIsRunning(true);
      const dataTransaction = response?.data?.data || [];
      setListDataSignature(prevDataArray => [
        ...prevDataArray,
        ...dataTransaction,
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsRunning(false);
    }
  };

  const refreshSolAndTokenHandler = async () => {
    try {
      const walletsInfo = getWalletsInfo(dataListWallet);
      const { sols } = await getSolsHandler(walletsInfo.walletPublicKeys);
      const result = dataListWallet.map((wallet, index) => ({
        ...wallet,
        solBalance: sols[index] ?? 0,
      }));
      setDataListWallet(result);

      await getTokenAndProfitHandler(dataListWallet);
    } catch (error) {
      setErrorMsg(['Refresh wallet failed!']);
    }
  };

  const initTokenAndProfitHandler = async (listWallets: any[]) => {
    try {
      await getTokenAndProfitHandler(listWallets);
    } catch (error) {
      throw error;
    }
  };

  const initSolWallets = async () => {
    let wallets: any[] = [],
      solBalances: any = [];
    try {
      const {
        status: walletStatus,
        data: { data: walletAddresses },
      } = await mainRequest(
        '/trader/wallet',
        {
          signal: walletController.signal,
        },
        'get',
      );

      if (walletStatus !== StatusCode.SUCCESS_COMMON) {
        throw new Error('Get wallets failed');
      }
      wallets = walletAddresses;
      const walletPublicAddresses = walletAddresses.map(
        wallet => new PublicKey(wallet.address) || '',
      );
      const { sols } = await getSolsHandler(walletPublicAddresses);
      solBalances = sols;
    } catch (error) {
      throw error;
    }
    const result = wallets?.map((item, index) => ({
      ...item,
      solBalance: solBalances[index] ?? 0,
      tokenBalance: 0,
      profit: 0,
    }));
    setDataListWallet(result);
    return result;
  };

  const refreshWallets = async () => {
    try {
      await getTokenDetail(id);
      const listWallets = await initSolWallets();
      if (listWallets.length) {
        await initTokenAndProfitHandler(listWallets);
      }
    } catch (error: any) {
      setErrorMsg([error.data?.message || 'Refresh wallets failed']);
    }
  };

  const initWallets = async () => {
    try {
      setIsExecuted(true);
      await getTokenDetail(id);
      const listWallets = await initSolWallets();
      if (listWallets.length) {
        await initTokenAndProfitHandler(listWallets);
      }
    } catch (error: any) {
      setErrorMsg([error.data?.message || 'Get wallets failed']);
    } finally {
      setIsExecuted(false);
    }
  };

  useEffect(() => {
    if (importPumpWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Wallet is imported!')]);
      refreshWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPumpWalletStatus]);

  useEffect(() => {
    if (buyPumpWalletStatus === RequestStatus.SUCCESS) {
      refreshWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyPumpWalletStatus]);

  useEffect(() => {
    if (sellPumpWalletStatus === RequestStatus.SUCCESS) {
      refreshWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellPumpWalletStatus]);

  useEffect(() => {
    if (deletePumpWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Wallet Deleted!')]);
      refreshWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePumpWalletStatus]);

  useEffect(() => {
    if (activeWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Active wallet succeed!')]);
      refreshWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWalletStatus]);

  useEffect(() => {
    if (unActiveWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Inactive wallet succeed!')]);
      refreshWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unActiveWalletStatus]);

  useEffect(() => {
    initWallets();
    return () => {
      localStorage.removeItem('token_detail');
      requestResetPump();
    };
  }, []);

  return (
    <CommonContainer className="top16">
      <Box sx={{ display: 'flex', gap: '8px', marginBottom: 1 }}>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={() => setOpen({ ...open, importWallet: true })}
        >
          <DownloadForOfflineIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Import
        </button>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={() => setOpen({ ...open, deleteAll: true })}
        >
          <DeleteIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Remove All
        </button>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={() => setOpen({ ...open, traderConfig: true })}
        >
          <TuneIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Trader Config
        </button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <CommonTable
            type={TableType.PUMPFUN_TRADER_DETAIL}
            data={dataListWallet}
            onReload={reloadData}
            idToken={id ? Number(id) : ''}
          />
          <PumpfunCard handleSubmitBuyAndSell={handleSubmitBuyAndSell} />
          <Stack
            sx={{ width: '100%', mt: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <button
              className="multi_color_btn button main_button_new font_weight_500"
              onClick={refreshSolAndTokenHandler}
            >
              <RefreshOutlinedIcon
                sx={{
                  width: '24px !important',
                  height: '24px !important',
                }}
              />
              Refresh
            </button>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              border: '1px solid #333',
              borderRadius: 1,
              backgroundColor: '#f0f0f0',
              width: '100%',
            }}
          >
            <Title>Transaction</Title>
            {[lightTheme].map((theme, index) => (
              <Box
                key={index}
                sx={{
                  maxHeight: '590px',
                  overflow: 'auto',
                  width: '-webkit-fill-available',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f0f0f0',
                    height: 400,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10px',
                    backgroundColor: '#555',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 1,
                      bgcolor: 'background.default',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {listDataSignature.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          flex: 1,
                          width: '100%',
                          wordWrap: 'nowrap',
                          cursor: 'pointer',
                          marginBottom: '2px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Link
                          href={`https://solscan.io/tx/${item.signature}`}
                          target="_blank"
                          sx={{
                            wordWrap: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            fontSize: '0.8rem',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            '&:hover': {
                              color: '#0d47a1',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {item.signature}
                        </Link>
                      </Box>
                    ))}
                    {listDataSignature.length === 0 && (
                      <Box
                        sx={{
                          flex: 1,
                          width: '100%',
                          wordWrap: 'break-word',
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          fontSize: '0.9rem',
                          lineHeight: '1.43',
                          letterSpacing: '0.01071em',
                          paddingLeft: '12px',
                          textAlign: 'center',
                        }}
                      >
                        {isRunning ? 'Loading...' : 'No data'}
                      </Box>
                    )}
                  </Box>
                </ThemeProvider>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <ImportWalletPopup
        open={open.importWallet}
        onSubmit={handleImportWalletBuy}
        onClose={() => setOpen({ ...open, importWallet: false })}
      />
      <ConfirmDeletePopup
        open={open.deleteAll}
        content={'Are you sure you want to delete all wallet?'}
        onSubmit={handleDeleteAll}
        onClose={() => setOpen({ ...open, deleteAll: false })}
      />

      <ConfigTraderPopup
        open={open.traderConfig}
        onSubmit={handleTraderConfig}
        onClose={() => setOpen({ ...open, traderConfig: false })}
      />

      <CommonLoading show={isExecuted} />
    </CommonContainer>
  );
};
