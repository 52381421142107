/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Container } from '@/components/token/components';
import { ImportBootTxPopup } from '@/components/token/components/importBootTx';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export interface DeleteForm {
  input: string;
}
export const PumpBootTxPage = (props: { type: any }) => {
  const { type } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const {
    requestPumpBootVolumeTxList,
    requestPumpBootTxList,
    pumpBootVolumeTxList,
    pumpBootTxList,
    requestPumpImportBootVolumeTx,
    requestImportPumpBootTx,
    importPumpBootTxStatus,
    getPumpBootVolumeTxListStatus,
    getPumpBootTxListStatus,
    importPumpBootVolumeTxStatus,
  } = usePumpHook();

  const reloadData = () => {
    if (type === TableType.PUMP_BOOT_VOLUME) {
      requestPumpBootVolumeTxList();
    } else {
      requestPumpBootTxList();
    }
  };

  useEffect(() => {
    if (type === TableType.PUMP_BOOT_VOLUME) {
      if (getPumpBootVolumeTxListStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to fetch tokens')]);
      }
    } else {
      if (getPumpBootTxListStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to fetch tokens')]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPumpBootTxListStatus, getPumpBootVolumeTxListStatus]);

  useEffect(() => {
    if (type === TableType.PUMP_BOOT_VOLUME) {
      if (importPumpBootVolumeTxStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to import token')]);
      }
      if (importPumpBootVolumeTxStatus === RequestStatus.SUCCESS) {
        setSuccessMsg([t('Token is imported!')]);
        reloadData();
      }
    } else {
      if (importPumpBootTxStatus === RequestStatus.ERROR) {
        setErrorMsg([t('Something went wrong. Unable to import token')]);
      }
      if (importPumpBootTxStatus === RequestStatus.SUCCESS) {
        setSuccessMsg([t('Token is imported!')]);
        reloadData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPumpBootTxStatus, importPumpBootVolumeTxStatus]);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getPumpBootTxListStatus,
        importPumpBootTxStatus,
        getPumpBootVolumeTxListStatus,
        importPumpBootVolumeTxStatus,
      ]),
    [
      getPumpBootTxListStatus,
      importPumpBootTxStatus,
      getPumpBootVolumeTxListStatus,
      importPumpBootVolumeTxStatus,
    ],
  );

  const onImport = () => {
    setOpen(true);
  };
  const importToken = (payload: any) => {
    if (type === TableType.PUMP_BOOT_VOLUME) {
      requestPumpImportBootVolumeTx(payload);
    } else {
      requestImportPumpBootTx(payload);
    }
    setOpen(false);
  };

  return (
    <CommonContainer className="top">
      <Box
        mt={4}
        mb={4}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={onImport}
        >
          Import token boot tx
        </button>
      </Box>
      <CommonTable
        type={type}
        data={
          type === TableType.PUMP_BOOT_VOLUME
            ? pumpBootVolumeTxList
            : pumpBootTxList
        }
        onReload={reloadData}
      />
      <ImportBootTxPopup
        open={open}
        onSubmit={importToken}
        onClose={() => setOpen(false)}
      />
      <CommonLoading show={loading} />
    </CommonContainer>
  );
};
