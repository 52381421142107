import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Title } from '@/components/login';
import { EDialogTypes } from '@/enums/holder';
import { RequestStatus } from '@/constants/API';
import { IHolderDialogProps } from '@/interfaces/holder';
import { CommonTable } from '@/components/layouts/table';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { TableType } from '@/components/layouts/table/table';
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import HolderPopups from '@/components/token/components/HolderPopups';
import { getHolderTokensRequest } from '@/stores/slices/token/token';

const HolderPage = () => {
  const dispatch = useAppDispatch();
  const { listHolderTokens, getHolderTokensStatus, importHolderTokenStatus } =
    useAppSelector(state => state.token);
  const [holderDialogs, setHolderDialogs] = useState<
    Omit<IHolderDialogProps, 'onClose'>
  >({
    isOpen: false,
    dialogType: null,
  });

  const onPageChange = (newPage: number) => {
    const newParams = { ...listHolderTokens.params, page: newPage };
    dispatch(getHolderTokensRequest(newParams));
  };

  const onOpenDialog = (dialogType: IHolderDialogProps['dialogType']) => {
    setHolderDialogs({
      ...holderDialogs,
      isOpen: true,
      dialogType,
    });
  };

  const onCloseDialog = () => {
    setHolderDialogs({
      ...holderDialogs,
      isOpen: false,
      dialogType: null,
    });
  };

  useEffect(() => {
    dispatch(getHolderTokensRequest(listHolderTokens.params));
  }, []);

  return (
    <CommonContainer
      className="top"
      sx={{
        position: 'relative',
      }}
    >
      <Title>Holder tokens</Title>
      <Stack
        sx={{
          width: '100%',
          my: 2,
        }}
        spacing={2}
        direction="row"
        justifyContent="end"
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={() => onOpenDialog(EDialogTypes.IMPORT_HOLDER_TOKEN)}
        >
          Import token
        </button>
      </Stack>
      <CommonTable
        type={TableType.HOLDER_TOKENS}
        data={listHolderTokens.data}
        pagination={{
          currentPage: listHolderTokens?.meta?.currentPage ?? 1,
          totalPages: listHolderTokens?.meta?.totalPages ?? 1,
          onPageChange,
        }}
      />

      <HolderPopups
        isOpen={holderDialogs.isOpen}
        dialogType={holderDialogs.dialogType}
        onClose={onCloseDialog}
      />
      <CommonLoading
        show={[getHolderTokensStatus, importHolderTokenStatus].includes(
          RequestStatus.REQUESTING,
        )}
      />
    </CommonContainer>
  );
};

export default HolderPage;
