/* eslint-disable max-lines */
import Pagination from '@/components/common/pagination';
import { ConfirmDeletePopup } from '@/components/token/components/confirmDeletePopup';
import { Box, TableBody, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { useMemo, useState } from 'react';

import { PaginationContainer } from './component';
import {
  BootTxBuySellHeader,
  BootTxHeader,
  CommonTableProps,
  HolderTokenDetailHeader,
  HolderTokenHeader,
  PumpfunTokenHeader,
  PumpfunTraderHeader,
  SnipHeader,
  TableType,
  TokenHeader,
  TopHeader,
  TransactionHeader,
} from './table';
import {
  BootBuyTxRow,
  BootVolumeTxRow,
  HolderTokenDetailRow,
  HolderTokenRow,
  ListTransactionRow,
  PumpfunTokenRow,
  PumpfunTraderDetailRow,
  PumpfunTraderRow,
  SnipRow,
  TokenRow,
  TopRow,
  WalletSellRow,
} from './tableRow';

export const CommonTable = ({
  type,
  data,
  pagination,
  onDelete,
  onAction,
  onReload,
  control,
  idToken,
}: CommonTableProps) => {
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const handleShowDeleteTokenConfirm = (id: number) => {
    setId(id);
    setOpenConfirmDelete(true);
  };

  const handleDeleteToken = () => {
    onDelete && onDelete(id);
    onReload && onReload();
    setOpenConfirmDelete(false);
  };

  const renderTable = useMemo(() => {
    switch (type) {
      case TableType.PUMPFUN_TOKEN:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <PumpfunTokenHeader />
              {data && !!data.length && (
                <TableBody>
                  {data.map((row, index) => (
                    <PumpfunTokenRow
                      key={row.id}
                      row={{ ...row, index }}
                      onDelete={handleShowDeleteTokenConfirm}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
            {!data ||
              (!data.length && (
                <Typography
                  sx={{ background: '#3f3f3f', padding: '12px' }}
                  fontSize={18}
                  color="#ccc"
                  textAlign="center"
                >
                  No Data
                </Typography>
              ))}
          </TableContainer>
        );
      case TableType.TOP:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TopHeader holders={data} />
              <TableBody>
                {data?.map((row, index) => (
                  <TopRow key={row.id} row={{ ...row, index }} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );

      case TableType.VENUE:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TokenHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <TokenRow
                    key={row.id}
                    row={{ ...row, index }}
                    onDelete={handleShowDeleteTokenConfirm}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.DETAIL:
        return (
          <TableContainer
            component={Paper}
            sx={{
              '& a': {
                color: '#FF9900 ',
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <SnipHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <SnipRow key={row.id} row={{ ...row, index }} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );

      case TableType.BOOT_TX:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <BootBuyTxRow
                    key={row.id}
                    row={{ ...row, index }}
                    type={TableType.BOOT_TX}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMP_BOOT_TX:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <BootBuyTxRow
                    key={row.id}
                    row={{ ...row, index }}
                    type={TableType.PUMP_BOOT_TX}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.BOOT_VOLUME:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <BootVolumeTxRow
                    key={row.id}
                    row={{ ...row, index }}
                    type={TableType.BOOT_VOLUME}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMP_BOOT_VOLUME:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <BootVolumeTxRow
                    key={row.id}
                    row={{ ...row, index }}
                    type={TableType.PUMP_BOOT_VOLUME}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.BOOT_BUY_SELL_TX:
        return (
          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
              stickyHeader
            >
              <BootTxBuySellHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <WalletSellRow
                    key={row.id}
                    row={{ ...row, index }}
                    control={control}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMP_BOOT_BUY_SELL_TX:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxBuySellHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <WalletSellRow
                    key={row.id}
                    row={{ ...row, index }}
                    control={control}
                  />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.LIST_TRANSACTION:
        return (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 440, background: '#fff !important' }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TransactionHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <ListTransactionRow key={row.id} row={{ ...row, index }} />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMPFUN_TRADER_DETAIL:
        return (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 550, background: '#fff !important' }}
          >
            <Table
              sx={{ minWidth: 1024, overflow: 'hidden' }}
              aria-label="sticky table"
              stickyHeader
            >
              <PumpfunTraderHeader data={data} />
              <TableBody>
                {data?.map((row, index) => (
                  <PumpfunTraderDetailRow
                    key={row.id}
                    row={{ ...row, index }}
                    id={idToken}
                  />
                ))}
              </TableBody>
            </Table>

            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );

      case TableType.PUMPFUN_TRADER_LIST:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BootTxHeader />
              <TableBody>
                {data?.map((row, index) => (
                  <PumpfunTraderRow key={row.id} row={{ ...row, index }} />
                ))}
              </TableBody>
            </Table>
            {data?.length === 0 || !data ? (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color={'#ccc'}
                textAlign={`center`}
              >
                No Data
              </Typography>
            ) : (
              <></>
            )}
          </TableContainer>
        );
      case TableType.HOLDER_TOKENS:
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <HolderTokenHeader />
              {data && data.length ? (
                <TableBody>
                  {data.map((row, index) => (
                    <HolderTokenRow
                      key={row.id}
                      row={{ ...row, index }}
                      onDelete={handleShowDeleteTokenConfirm}
                    />
                  ))}
                </TableBody>
              ) : (
                <Typography
                  sx={{ background: '#3f3f3f', padding: '12px' }}
                  fontSize={18}
                  color="#ccc"
                  textAlign="center"
                >
                  No Data
                </Typography>
              )}
            </Table>
          </TableContainer>
        );
      case TableType.HOLDER_TOKEN_DETAIL:
        return (
          <TableContainer component={Paper}>
            {data && data.length ? (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <HolderTokenDetailHeader />
                <TableBody>
                  {data.map((row, index) => (
                    <HolderTokenDetailRow
                      key={`holder-token-${index}`}
                      row={{ ...row, index }}
                      onDelete={handleShowDeleteTokenConfirm}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{ background: '#3f3f3f', padding: '12px' }}
                fontSize={18}
                color="#ccc"
                textAlign="center"
              >
                No Data
              </Typography>
            )}
          </TableContainer>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [type, data, onDelete]);

  return (
    <Box sx={{}}>
      <TableContainer
        className={type === TableType.TOP ? 'top' : ''}
        sx={{
          '& .MuiTableCell-root': {
            color: '#fff',
            background: '#2c2d30',
            borderBottom: 'none',
          },
          '& td.MuiTableCell-root': {
            color: '#fff',
            background: '#3f3f3f',
            borderBottom: 'none',
          },
          '& tr.MuiTableRow-root:hover .MuiTableCell-root': {
            background: '#474747',
          },

          boxShadow: '6px 6px 9px 3px rgba(0,0,0,0.2)',
        }}
      >
        {renderTable}
      </TableContainer>
      {pagination && (
        <PaginationContainer>
          <Pagination {...pagination} />
        </PaginationContainer>
      )}
      <ConfirmDeletePopup
        open={openConfirmDelete}
        onSubmit={handleDeleteToken}
        onClose={() => setOpenConfirmDelete(false)}
      />
    </Box>
  );
};
