import { Title } from '@/components/login';
import { CommonContainer } from '@/components/layouts/content';
import { Box, Stack } from '@mui/material';
import Connect2Phantom from '@/pages/token/Connect2Phantom';
import { useTranslation } from 'react-i18next';
import BurnSolForm from '@/components/token/components/BurnSolForm';

const BurnTokenPage = () => {
  const { t } = useTranslation();

  return (
    <CommonContainer className="top">
      <Title>{t('title.burn_sol.main_title')}</Title>
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 700,
        }}
      >
        <Stack direction="row" justifyContent="end" sx={{ mb: 2 }}>
          <Connect2Phantom />
        </Stack>
        <BurnSolForm />
      </Box>
    </CommonContainer>
  );
};

export default BurnTokenPage;
