/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import { Title } from '@/components/login';
import { BtnContainer, JumboTron } from '@/components/token/components';
import { Authority } from '@/components/token/components/authority';
import { Info, Text } from '@/components/token/components/info';
import { LPTab } from '@/components/token/components/lp';
import { Market } from '@/components/token/components/market';
import { Meta } from '@/components/token/components/meta';
import { POOL_TYPE, Pool } from '@/components/token/components/pool';
import { POPUP, TokenPopup } from '@/components/token/components/popup';
import { RugForm } from '@/components/token/components/rugForm';
import { SolForm, SolFormType } from '@/components/token/components/solForm';
import TabComponent from '@/components/token/components/tabs';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting, roundNumber } from '@/utils/helper';
import { Alert, Box } from '@mui/material';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export interface DeleteForm {
  input: string;
}
export const TokenDetailsPage = () => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<POPUP>(POPUP.HOLDER);
  const [marketValue, setMarketValue] = useState<string>('');
  const location = useLocation();
  const queries = useParams();

  const {
    getTokenInfoStatus,
    createTokenAddressStatus,
    requestCreateTokenAddress,
    requestTokenDetails,
    requestResetToken,
    getTokenDetailsStatus,
    revokeMintTokenStatus,
    revokeFreezeTokenStatus,
    requestTokenHolders,
    requestRefreshToken,
    requestGetSnipSol,
    requestUpdateTokenInfo,
    tokenDetails,
    holders,
    requestRevokeMintToken,
    requestRevokeFreezeToken,
    revokeMsg,
    requestRugPoolToken,
    requestAutoRugPoolToken,
    requestburnToken,
    burnTokenStatus,
    refreshTokenStatus,
    rugPoolTokenStatus,
    updateTokenInfoStatus,
    requestSnipSol,
    snipSolStatus,
    getSnipSolStatus,
    multiSenderData,
    requestSendMultiSol,
    sendMultiSolStatus,
    marketData,
    requestCreateBook,
    requestCreatePool,
    requestCreatePoolV2,
    createBookStatus,
    createPoolStatus,
    createPoolV2Status,
    autoRugPoolTokenStatus,
  } = useTokenHook();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getTokenInfoStatus,
        revokeMintTokenStatus,
        revokeFreezeTokenStatus,
        burnTokenStatus,
        rugPoolTokenStatus,
        refreshTokenStatus,
        getTokenDetailsStatus,
        snipSolStatus,
        getSnipSolStatus,
        updateTokenInfoStatus,
        sendMultiSolStatus,
        createBookStatus,
        createPoolStatus,
        createPoolV2Status,
        autoRugPoolTokenStatus,
      ]),
    [
      getTokenInfoStatus,
      revokeMintTokenStatus,
      revokeFreezeTokenStatus,
      burnTokenStatus,
      rugPoolTokenStatus,
      refreshTokenStatus,
      getTokenDetailsStatus,
      snipSolStatus,
      getSnipSolStatus,
      updateTokenInfoStatus,
      sendMultiSolStatus,
      createBookStatus,
      createPoolStatus,
      createPoolV2Status,
      autoRugPoolTokenStatus,
    ],
  );

  const showNoData = useMemo(
    () =>
      getTokenInfoStatus !== RequestStatus.IDLE &&
      (!holders || holders.length === 0),
    [holders, getTokenInfoStatus],
  );
  // const showNoSnipData = useMemo(
  //   () =>
  //     getSnipSolStatus !== RequestStatus.IDLE && (!snips || snips.length === 0),
  //   [snips, getSnipSolStatus],
  // );

  const reloadData = () => {
    requestTokenHolders({ id: queries?.id });
    requestTokenDetails({ id: queries?.id });
    requestGetSnipSol({ id: queries?.id });
  };

  // const reloadTokenDetails = () => {
  //   requestTokenDetails({ id: queries?.id });
  // };

  // TODO
  const onRugPool = () => {
    requestRugPoolToken({ id: queries?.id });
  };
  // TODO
  const onFakeVolumn = () => {};

  const onRevokeFreezeToken = () => {
    requestRevokeFreezeToken(queries?.id);
  };

  const onRevokeMintToken = () => {
    requestRevokeMintToken(queries?.id);
  };

  const onBurnLP = () => {
    requestburnToken({ id: queries?.id });
  };

  const onUpdate = () => {
    setPopupType(POPUP.METADATA);
    setOpenPopup(true);
  };

  const onCreate = () => {
    setPopupType(POPUP.HOLDER);
    setOpenPopup(true);
  };

  // const onCreateSnip = () => {
  //   setPopupType(POPUP.SNIP);
  //   setOpenPopup(true);
  // };

  const onWalletFormSubmit = (formData: any) => {
    setOpenPopup(false);
    if (popupType === POPUP.HOLDER) {
      requestCreateTokenAddress({
        tokenId: queries?.id ? +queries?.id : 0,
        privateKeys: formData.privateKeys?.split(/\r?\n/) ?? [],
      });
    } else if (popupType === POPUP.SNIP) {
      requestSnipSol({
        ...formData,
        id: queries?.id ? +queries?.id : 0,
      });
    } else {
      requestUpdateTokenInfo({
        ...formData,
        id: queries?.id ? +queries?.id : 0,
      });
    }
  };

  const onSwapSol = (formData: any) => {
    formData.id = queries?.id;
    formData.wallets = formData.addresses;
    delete formData.balance;
    delete formData.addresses;
    requestSendMultiSol(formData);
  };

  const onCreateMarket = (formData: any) => {
    requestCreateBook({
      id: queries?.id,
      lotSize: formData.lotSize,
      tickSize: formData.tickSize,
    });
  };

  const onCreatePool = (formData: any) => {
    delete formData.baseToken;
    delete formData.quoteToken;
    requestCreatePool({ ...formData, id: queries?.id });
  };

  const onCreatePoolV2 = (formData: any) => {
    delete formData.baseToken;
    delete formData.quoteToken;
    requestCreatePoolV2({ ...formData, id: queries?.id });
  };

  const onAutoRugPool = (formData: any) => {
    requestAutoRugPoolToken({ ...formData, id: queries?.id });
  };

  useEffect(() => {
    if (tokenDetails?.market) {
      try {
        const data = JSON.parse(tokenDetails?.market);
        setMarketValue(data.marketId);
      } catch (e) {}
    }
    if (marketData) {
      setMarketValue(marketData.marketId);
    }
  }, [tokenDetails, marketData]);

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createTokenAddressStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Private key address added!')]);
      setOpenPopup(false);
      reloadData();
    }

    if (createTokenAddressStatus === RequestStatus.ERROR) {
      setOpenPopup(false);
      setErrorMsg([t('Something went wrong. Unable to add private key')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTokenAddressStatus]);

  useEffect(() => {
    if (updateTokenInfoStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Metadata updated!')]);
      setOpenPopup(false);
      reloadData();
    }

    if (updateTokenInfoStatus === RequestStatus.ERROR) {
      setOpenPopup(false);
      setErrorMsg([t('Something went wrong. Unable to update metadata')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTokenInfoStatus]);

  useEffect(() => {
    if (revokeFreezeTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Successfully revoke freeze token!']);
      reloadData();
    }

    if (revokeFreezeTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to revoke freeze token')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeFreezeTokenStatus]);

  useEffect(() => {
    if (autoRugPoolTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Successfully!']);
      setTimeout(() => reloadData(), 1000);
    }

    if (autoRugPoolTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to auto rug')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRugPoolTokenStatus]);

  useEffect(() => {
    if (revokeMintTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Successfully revoke mint token!']);
      reloadData();
    }

    if (revokeMintTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to revoke mint token')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeMintTokenStatus]);

  useEffect(() => {
    if (burnTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Successfully burn LP!']);
      reloadData();
    }

    if (burnTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to burn LP')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [burnTokenStatus]);

  useEffect(() => {
    if (sendMultiSolStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Successfully sent multiple wallets!']);
      // reloadData();
    }

    if (sendMultiSolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to send multiple wallets')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMultiSolStatus]);

  useEffect(() => {
    if (rugPoolTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg(['Successfully rug pool token!']);
      reloadData();
    }

    if (rugPoolTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to rug pool token')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rugPoolTokenStatus]);

  useEffect(() => {
    if (refreshTokenStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to refresh')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenStatus]);

  useEffect(() => {
    if (snipSolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to snip')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snipSolStatus]);

  useEffect(() => {
    if (createBookStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to open book')]);
    }
    if (createBookStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBookStatus]);

  useEffect(() => {
    if (createPoolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to open new pool')]);
    }
    if (createPoolStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPoolStatus]);

  useEffect(() => {
    if (createPoolV2Status === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to open new pool')]);
    }
    if (createPoolV2Status === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPoolV2Status]);

  /** fetch data based on query string */
  useEffect(() => {
    reloadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const holderList = useMemo(() => {
    return holders.map((holder: any) => {
      return {
        ...holder,
        token_account: tokenDetails,
        percentage: roundNumber(
          (holder.tokenBalance * 100) / tokenDetails?.supply,
        ),
        value: holder.tokenBalance,
      };
    });
  }, [holders, tokenDetails]);

  const tabs = [
    {
      label: 'Market',
      content: (
        <Box
          sx={{
            position: 'relative',
            '& .MuiFormControl-root': { width: '100%' },
            '& .MuiAlert-root': {
              background: '#A43399',
              margin: '30px 0 10px',
            },
            '& .MuiAlert-icon, & .MuiAlert-message': {
              color: 'orange !important',
            },
          }}
        >
          <Market onCreate={onCreateMarket} data={tokenDetails} />
          <Box mt={1}>
            <Pool
              onCreate={onCreatePool}
              data={tokenDetails}
              marketValue={marketValue}
            />
          </Box>
        </Box>
      ),
    },
    {
      label: 'Market And Snip',
      content: (
        <Box
          sx={{
            position: 'relative',
            '& .MuiFormControl-root': { width: '100%' },
            '& .MuiAlert-root': {
              background: '#A43399',
              margin: '30px 0 10px',
            },
            '& .MuiAlert-icon, & .MuiAlert-message': {
              color: 'orange !important',
            },
          }}
        >
          <Market onCreate={onCreateMarket} data={tokenDetails} />
          <Box mt={1}>
            <Pool
              onCreate={onCreatePoolV2}
              data={tokenDetails}
              marketValue={marketValue}
              type={POOL_TYPE.SNIP}
            />
          </Box>
        </Box>
      ),
    },
    {
      label: 'Token MultiSender',
      content: (
        <>
          {multiSenderData?.tools && multiSenderData?.tools?.length > 0 && (
            <>
              <Alert
                severity="warning"
                sx={{
                  color: '#FFA500',
                  '& a': { color: '#FFA07A', fontWeight: 600 },
                }}
              >
                We have sent the token, please check the transaction. If the
                transaction fails, you can use &nbsp;
                <a
                  href="https://cointool.app/multiSender/sol"
                  target="_blank"
                  rel="noreferrer"
                >
                  cointool
                </a>
                &nbsp; to send tokens with the syntax below:
              </Alert>
              <JumboTron mt={4} mb={4}>
                <Title>Cointool multi sender syntax</Title>
                {multiSenderData?.tools?.map((tool: string, idx: number) => (
                  <>
                    <Text className="white">{tool}</Text>
                    <br />
                  </>
                ))}
              </JumboTron>
            </>
          )}
          <Box
            sx={{
              position: 'relative',
              maxWidth: '660px',
              margin: '40px auto',
            }}
          >
            <SolForm onSubmit={onSwapSol} type={SolFormType.SWAP} />
          </Box>
        </>
      ),
    },
    {
      label: 'Auto Rug',
      content: (
        <RugForm
          onSubmit={onAutoRugPool}
          isAutoRug={tokenDetails?.isAutoRug}
          volumeRug={
            tokenDetails?.volumeRug
              ? (tokenDetails?.volumeRug / LAMPORTS_PER_SOL).toString()
              : ''
          }
        />
      ),
    },
  ];

  return (
    <CommonContainer className="top">
      {revokeMsg && (
        <Alert
          severity="success"
          sx={{
            margin: '10px 0 30px',
            '& a': {
              color: '#fff',
            },
          }}
        >
          <a
            href={`https://solscan.io/tx/${revokeMsg}`}
            target="_blank"
            rel="noreferrer"
          >{`https://solscan.io/tx/${revokeMsg}`}</a>
        </Alert>
      )}
      {multiSenderData?.tx?.length > 0
        ? multiSenderData?.tx?.map((tx: string, idx: number) => (
            <Alert
              key={idx}
              severity="success"
              sx={{
                margin: '10px 0 30px',
                '& a': {
                  color: '#fff',
                },
              }}
            >
              <a
                href={`https://solscan.io/tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >{`https://solscan.io/tx/${tx}`}</a>
            </Alert>
          ))
        : ''}
      <BtnContainer>
        <Info data={tokenDetails} />
        <Authority
          onFreeClick={onRevokeFreezeToken}
          onMintClick={onRevokeMintToken}
          isDisabledMint={tokenDetails?.isRenounce}
          isDisabledFree={tokenDetails?.isFreeze}
          revokeTx={tokenDetails?.freezeTx}
          renounceTx={tokenDetails?.renounceTx}
        />
        <LPTab
          onBurnLP={onBurnLP}
          onRugPool={onRugPool}
          onFakeVolumn={onFakeVolumn}
          isDisableBurn={tokenDetails?.isBurnLp}
          isDisableRug={tokenDetails?.isRemovePool}
          lpTx={tokenDetails?.burnLpTx}
          poolTx={tokenDetails?.removePoolTx}
        />
        <Meta data={tokenDetails} onUpdate={onUpdate} />
      </BtnContainer>

      <TabComponent tabs={tabs} />

      <TokenPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onSubmit={onWalletFormSubmit}
        type={popupType}
        data={tokenDetails}
      />

      <CommonLoading show={loading} />
    </CommonContainer>
  );
};
