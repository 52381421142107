/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import { CommonContainer } from '@/components/layouts/content';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { ImportBootTxPopup } from '@/components/token/components/importBootTx';
import { RequestStatus } from '@/constants/API';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { checkIsRequesting } from '@/utils/helper';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const PumpfunTrader = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const { setSuccessMsg } = useGlobalHook();

  const {
    requestGetListPumpToken,
    getListPumpTokenStatus,
    listPumpToken,
    requestImportPumpToken,
    importPumpTokenStatus,
    requestResetPump,
  } = usePumpHook();

  const onImport = () => {
    setOpen(true);
  };

  const importToken = (payload: any) => {
    requestImportPumpToken({
      addresses: [payload.contractAddress],
    });
    setOpen(false);
  };

  const loading = useMemo(
    () => checkIsRequesting([getListPumpTokenStatus, importPumpTokenStatus]),
    [getListPumpTokenStatus, importPumpTokenStatus],
  );

  useEffect(() => {
    if (importPumpTokenStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Token is imported!')]);
      requestGetListPumpToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPumpTokenStatus]);

  useEffect(() => {
    requestGetListPumpToken();

    return () => {
      requestResetPump();
    };
  }, []);

  return (
    <CommonContainer className="top">
      <Box
        mt={4}
        mb={4}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="main_btn multi_color_btn button new_btn min_150"
          onClick={onImport}
        >
          Import token
        </button>
      </Box>
      <CommonTable type={TableType.PUMPFUN_TRADER_LIST} data={listPumpToken} />
      <ImportBootTxPopup
        open={open}
        onSubmit={importToken}
        onClose={() => setOpen(false)}
      />
      <CommonLoading show={loading} />
    </CommonContainer>
  );
};
