import logo from '@/assets/logo_mm.png';
import CommonLoading from '@/components/common/CommonLoading';
import { Loader } from '@/components/common/loader';
import { LogoWrapper } from '@/components/layouts/sidebar/components';
import {
  Container,
  FormGroup,
  LoginContainer,
  LoginWrapper,
  SignUpButton,
  Title,
} from '@/components/login';
import { RequestStatus } from '@/constants/API';
import Path from '@/constants/path';
import { useAppDispatch } from '@/hooks';
import useAuthHook from '@/hooks/useAuthHook';
import useGlobalHook from '@/hooks/useGlobalHook';
import { signUpResetState } from '@/stores/slices/auth/auth';
import { checkIsRequesting } from '@/utils/helper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface SignUpFormState {
  email: string;
  password: string;
  confirm_password?: string;
}

const SignUpPage: React.FC = () => {
  const { t } = useTranslation();

  const { signUpRequestStatus, requestSignUp, errorMess, message }: any =
    useAuthHook();
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  }: any = useForm();

  const loading = useMemo(
    () => checkIsRequesting([signUpRequestStatus]),
    [signUpRequestStatus],
  );
  const dispatch = useAppDispatch();

  const watchPassword = watch('password', '');
  const watchConfirmPassword = watch('confirm_password', '');

  const navigate = useNavigate();

  useEffect(() => {
    if (signUpRequestStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([message]);
      dispatch(signUpResetState());
      navigate(Path.LOGIN);
    }
    if (signUpRequestStatus === RequestStatus.ERROR) {
      setErrorMsg([errorMess]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpRequestStatus]);

  // Real-time validation for password match
  useEffect(() => {
    if (watchConfirmPassword !== '') {
      if (watchPassword !== watchConfirmPassword) {
        setError('confirm_password', {
          type: 'manual',
          message: 'Passwords do not match',
        });
      } else {
        clearErrors('confirm_password');
      }
    }
  }, [watchPassword, watchConfirmPassword, setError, clearErrors]);

  const onSubmit = (data, event) => {
    event.preventDefault();
    requestSignUp(data);
  };

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(show => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  // const handleMouseUpPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  // ) => {
  //   event.preventDefault();
  // };

  return (
    <Container>
      <LogoWrapper className="big_logo" to={Path.HOME}>
        <img src={logo} className="App-logo" alt="logo" />
      </LogoWrapper>

      <LoginContainer>
        <LoginWrapper>
          <Title>{t('label.signUp')}</Title>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 2 }}
          >
            <FormGroup>
              <TextField
                sx={{ background: '#fff' }}
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                margin="normal"
                {...register('email', {
                  required: t('message.error.inputRequired', {
                    param: t('label.email'),
                  }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
              />
            </FormGroup>

            <FormGroup>
              <FormControl
                sx={{ background: '#fff', width: '100%', mt: 2 }}
                variant="outlined"
              >
                <TextField
                  sx={{ background: '#fff' }}
                  fullWidth
                  label={t('label.password')}
                  variant="outlined"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: t('message.error.inputRequired', {
                      param: t('label.password'),
                    }),
                  })}
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </FormGroup>

            <FormGroup>
              <FormControl
                sx={{ background: '#fff', width: '100%', mt: 2 }}
                variant="outlined"
              >
                <TextField
                  sx={{ background: '#fff' }}
                  fullWidth
                  label={t('label.confirmPassword')}
                  variant="outlined"
                  name="confirm_password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('confirm_password', {
                    required: t('message.error.inputRequired', {
                      param: t('label.confirmPassword'),
                    }),
                    validate: value =>
                      value === watch('password') || 'Passwords do not match',
                  })}
                  error={!!errors.confirm_password}
                  helperText={
                    errors.confirm_password
                      ? errors.confirm_password.message
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {confirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </FormGroup>

            <Button
              className="multi_color_btn main_btn"
              type="submit"
              fullWidth
              sx={{ mt: 3 }}
            >
              {t('label.signUp')}
            </Button>
            <SignUpButton
              className="main_btn btn_sign_up"
              onClick={() => {
                navigate(Path.LOGIN);
              }}
            >
              {t('label.signIn')}
            </SignUpButton>
          </Box>
        </LoginWrapper>
      </LoginContainer>
      <CommonLoading show={loading} />
    </Container>
  );
};

export default SignUpPage;
