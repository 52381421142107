import { Title } from '@/components/login';
import { CommonContainer } from '@/components/layouts/content';
import WithdrawForm from '@/components/token/components/WithdrawForm';
import { Box } from '@mui/material';
import { EWithdrawForms } from '@/enums/holder';

const BitgetWithdrawPage = () => {
  return (
    <CommonContainer className="top">
      <Title>Bitget Withdraw</Title>
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 700,
        }}
      >
        <WithdrawForm mode={EWithdrawForms.BITGET} />
      </Box>
    </CommonContainer>
  );
};

export default BitgetWithdrawPage;
