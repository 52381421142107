import { Backdrop } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled, { keyframes } from 'styled-components';

interface ILoaderProps {
  show: boolean;
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loading = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid #111;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spin} 2s linear infinite;
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6666;
  pointer-events: none;
`;

const CommonLoading = ({ show }: ILoaderProps) => {
  return (
    <Backdrop
      sx={(theme: Theme) => ({
        zIndex: theme.zIndex.drawer + 1,
      })}
      open={show}
    >
      <Loading />
    </Backdrop>
  );
};

export default CommonLoading;
