export const MoreIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#000000"
      fillRule="evenodd"
      d="M5,9 C4.44772,9 4,8.55228 4,8 C4,7.44772 4.44772,7 5,7 C5.55228,7 6,7.44772 6,8 C6,8.55228 5.55228,9 5,9 Z M9,8 C9,8.55228 8.55228,9 8,9 C7.44772,9 7,8.55228 7,8 C7,7.44772 7.44772,7 8,7 C8.55228,7 9,7.44772 9,8 Z M11,9 C10.4477,9 10,8.55228 10,8 C10,7.44772 10.4477,7 11,7 C11.5523,7 12,7.44772 12,8 C12,8.55228 11.5523,9 11,9 Z M0,8 C0,5.23858 2.23858,3 5,3 L11,3 C13.7614,3 16,5.23858 16,8 C16,10.76142 13.7614,13 11,13 L5,13 C2.23858,13 0,10.76142 0,8 Z M5,5 C3.34315,5 2,6.34315 2,8 C2,9.65685 3.34315,11 5,11 L11,11 C12.6569,11 14,9.65685 14,8 C14,6.34315 12.6569,5 11,5 L5,5 Z"
    />
  </svg>
);
