/* eslint-disable max-lines */
import CommonLoading from '@/components/common/CommonLoading';
import ConfirmPopUp from '@/components/common/ConfirmPopup';
import { Loader } from '@/components/common/loader';
import { Container } from '@/components/token/components';
import { SolForm } from '@/components/token/components/solForm';
import { RequestStatus } from '@/constants/API';
import useConnectWallet from '@/hooks/useConnectWallet';
import useGlobalHook from '@/hooks/useGlobalHook';
import useTokenHook from '@/hooks/useTokenHook';
import { checkIsRequesting, encodedTransaction } from '@/utils/helper';
import { Alert } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SendSolPage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [valueSubmit, setValueSubmit] = useState<any>({});
  const { isLoading, publicKey, handleSignAndSendAllTransaction } =
    useConnectWallet();

  const {
    createTokenInfoStatus,
    requestResetToken,
    sendSolStatus,
    requestSendSol,
    requestSendSolV2,
    listTransaction,
  } = useTokenHook();

  const { setErrorMsg, setSuccessMsg } = useGlobalHook();

  const loading = useMemo(
    () => checkIsRequesting([sendSolStatus]),
    [sendSolStatus],
  );

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sendSolStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Sol transfered!')]);
    }

    if (sendSolStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to transfrer sol')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSolStatus]);

  const onSubmit = (formDt: any) => {
    const value = {
      receivers: formDt.handleValue,
      sender: publicKey?.toBase58(),
    };
    setValueSubmit(value);
    setOpen(true);
  };

  const sendSol = () => {
    requestSendSolV2(valueSubmit);
    // requestSendSol(valueSubmit);
  };

  useEffect(() => {
    if (listTransaction.length > 0) {
      const handleListTransaction = listTransaction.map(item => {
        return encodedTransaction(item);
      });
      if (handleListTransaction) {
        handleSignAndSendAllTransaction(handleListTransaction);
      }
    }
  }, [listTransaction]);

  useEffect(() => {
    return () => requestResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="medium">
      <SolForm onSubmit={onSubmit} />
      <CommonLoading show={loading || isLoading} />
      <ConfirmPopUp
        open={open}
        onClose={() => setOpen(false)}
        title="Transfer SOL"
        actionTitle="Transfer"
        onSubmit={sendSol}
      >
        <Alert severity="warning">Do you want to transfer SOL!</Alert>
      </ConfirmPopUp>
    </Container>
  );
};
