/* eslint-disable max-lines */
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import PopUp from '@/components/common/Popup';
import { InputType } from '@/components/form/customSelect';
import NumberInput from '@/components/form/numberInput';
import { EMevProtectModes } from '@/enums/trader';
import usePumpHook from '@/hooks/usePumpHook';
import {
  Box,
  Divider,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AntSwitch } from '../table/component';
import { isEmpty } from 'lodash';

export interface confirmDeleteProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const CardContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #c4c6ce;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 3px 11px 15px -5px rgba(0, 0, 0, 0.2);
`;

const defaultTipValue = [
  {
    title: 'Turbo 0.01',
    value: '0.01',
  },
  {
    title: 'Fast 0.001',
    value: '0.001',
  },
  {
    title: 'Medium 0.0001',
    value: '0.0001',
  },
];

export function ConfigTraderPopup({
  open,
  onClose,
  onSubmit,
}: confirmDeleteProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const [valueMEV, setValueMEV] = useState('');
  const [mevState, setMevState] = useState<{
    active: boolean;
    mode: EMevProtectModes;
  }>({
    active: false,
    mode: EMevProtectModes.INIT,
  });

  const { requestGetSettingTrade, settingTrade } = usePumpHook();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    slipBuyPercent?: number;
    slipSellPercent?: number;
    gasBuy: number;
    gasSell: number;
    tipMEV: string | number;
  }>({
    defaultValues: {},
  });

  const watchValueTipMEV = watch('tipMEV');

  const reloadData = () => {
    requestGetSettingTrade();
  };
  const onCancel = (e: any) => {
    e.preventDefault();
    onClose();
  };
  const handleSave = data => {
    onSubmit({
      ...data,
      tipMEV: mevState.active ? data.tipMEV : '',
      antiMEV: mevState.active,
    });
  };

  const mevModeChangeHandler = (e, value: EMevProtectModes) => {
    if (!value) return;
    setMevState({
      ...mevState,
      mode: value,
    });
  };

  const renderMevInput = () => {
    switch (mevState.mode) {
      case EMevProtectModes.AUTO:
        return (
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            {defaultTipValue.map(tip => (
              <CustomButton
                width={'31%'}
                key={tip.value}
                buttonType={CustomButtonType.CUSTOM}
                className={valueMEV === tip.value ? 'active' : ''}
                title={tip.title}
                backgroundColorBtn="#a1a1aa"
                textColor="#fff"
                onClick={() => {
                  setValueMEV(tip.value);
                  setValue('tipMEV', tip.value);
                }}
              />
            ))}
          </Stack>
        );

      case EMevProtectModes.MANUAL:
        return (
          <Controller
            control={control}
            name="tipMEV"
            render={({ field: { value, onChange } }) => (
              <NumberInput
                label={''}
                value={value}
                onChange={onChange}
                placeholder="Tip MEV"
                noBottom
                error={errors?.tipMEV?.message}
              />
            )}
          />
        );
    }
  };

  /** fetch data based on query string */
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        reloadData();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const isIncludeDefaultTip = defaultTipValue
      .map(tip => tip.value)
      .includes(settingTrade.tipMEV);
    reset(settingTrade);
    setMevState({
      ...mevState,
      active: settingTrade.antiMEV ?? false,
      mode: isEmpty(settingTrade.tipMEV)
        ? EMevProtectModes.INIT
        : isIncludeDefaultTip
        ? EMevProtectModes.AUTO
        : EMevProtectModes.MANUAL,
    });
    if (isIncludeDefaultTip) {
      setValueMEV(settingTrade.tipMEV);
    } else {
      setValue('tipMEV', settingTrade.tipMEV);
    }
  }, [settingTrade]);

  useEffect(() => {
    if (watchValueTipMEV) {
      setValueMEV(watchValueTipMEV + '');
    }
  }, [watchValueTipMEV]);

  return (
    <PopUp title={'Config Trade'} onClose={onClose} open={open}>
      <Box component="form">
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Divider sx={{ marginBottom: 2 }}>Buy</Divider>
              <Controller
                control={control}
                name="slipBuyPercent"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Slippage'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.slipBuyPercent?.message}
                      placeholder="Slippage"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="gasBuy"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Gas'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.gasBuy?.message}
                      placeholder="Gas"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Divider sx={{ marginBottom: 2 }}>Sell</Divider>
              <Controller
                control={control}
                name="slipSellPercent"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Slippage'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.slipSellPercent?.message}
                      placeholder="Slippage"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="gasSell"
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <NumberInput
                      fromType={InputType.POP_UP}
                      label={'Gas'}
                      value={value}
                      onChange={onChange}
                      isRequired
                      error={errors?.gasSell?.message}
                      placeholder="Gas"
                    />
                  );
                }}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider sx={{ marginBottom: 2 }}>MEV Protection</Divider>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <AntSwitch
                    checked={mevState.active}
                    onChange={() => {
                      setMevState({
                        ...mevState,
                        active: !mevState.active,
                      });
                    }}
                  />
                  {mevState.active && (
                    <ToggleButtonGroup
                      exclusive
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      size="small"
                      value={mevState.mode}
                      onChange={mevModeChangeHandler}
                    >
                      <ToggleButton
                        value={EMevProtectModes.MANUAL}
                        className="multi_color_btn"
                        sx={{
                          fontFamily: 'inherit',
                          minWidth: 150,
                          textTransform: 'none',
                        }}
                      >
                        Manual
                      </ToggleButton>
                      <ToggleButton
                        value={EMevProtectModes.AUTO}
                        className="multi_color_btn"
                        sx={{
                          fontFamily: 'inherit',
                          minWidth: 150,
                          textTransform: 'none',
                        }}
                      >
                        Auto
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                  {mevState.active &&
                    mevState.mode !== EMevProtectModes.INIT && (
                      <Box
                        sx={{
                          ml: 4,
                          width: '100%',
                          '& .active': {
                            'background-color': '#2C2D30 !important',
                          },
                        }}
                      >
                        {renderMevInput()}
                      </Box>
                    )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '150px 150px',
                gap: '20px',
                marginTop: '32px',
              }}
            >
              <button
                className="main_btn button new_btn multi_color_btn secondary_btn"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                className="main_btn button new_btn multi_color_btn"
                onClick={handleSubmit(handleSave)}
              >
                Save
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </PopUp>
  );
}
