import React from 'react';

const WithdrawIcon: React.FC = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 53 53"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                d="M6.7416992,47.8579102c-0.1279297,0-0.2558732-0.0488281-0.3535156-0.1464844l-1.9321289-1.9316406
                   c-0.1953125-0.1953125-0.1953125-0.5117188,0-0.7070313s0.5117188-0.1953125,0.7070313,0l1.5786133,1.578125l1.5786133-1.578125
                   c0.1953125-0.1953125,0.5117188-0.1953125,0.7070313,0s0.1953125,0.5117188,0,0.7070313l-1.9321289,1.9316406
                   C6.9975586,47.809082,6.8696289,47.8579102,6.7416992,47.8579102z"
              />
            </g>
            <g>
              <path
                d="M6.7416992,47.6655273c-0.2763672,0-0.5-0.2236328-0.5-0.5v-6.2099609c0-0.2763672,0.2236328-0.5,0.5-0.5
                   s0.5,0.2236328,0.5,0.5v6.2099609C7.2416992,47.4418945,7.0180664,47.6655273,6.7416992,47.6655273z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M42.8251953,36.6079102c-0.1279297,0-0.2558594-0.0488281-0.3535156-0.1464844l-1.9321289-1.9316406
                   c-0.1953125-0.1953125-0.1953125-0.5117188,0-0.7070313s0.5117188-0.1953125,0.7070313,0l1.5786133,1.578125l1.5786133-1.578125
                   c0.1953125-0.1953125,0.5117188-0.1953125,0.707016,0c0.1953278,0.1953125,0.1953278,0.5117188,0,0.7070313
                   l-1.9321136,1.9316406C43.0810547,36.559082,42.953125,36.6079102,42.8251953,36.6079102z"
              />
            </g>
            <g>
              <path
                d="M42.8251953,36.4155273c-0.2763672,0-0.5-0.2236328-0.5-0.5v-6.2099609c0-0.2763672,0.2236328-0.5,0.5-0.5
                   s0.5,0.2236328,0.5,0.5v6.2099609C43.3251953,36.1918945,43.1015625,36.4155273,42.8251953,36.4155273z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            d="M22.5628052,28.0119629c0.1931152,0.1118164,0.4269409,0.1628418,0.6911621,0.1628418
           c0.416687,0,0.7317505-0.1525879,0.9451904-0.4575195c0.1219482-0.1728516,0.2438965-0.4675293,0.3557129-0.8841553h-2.6221313
           C21.9632568,27.4023438,22.1665039,27.7883301,22.5628052,28.0119629z"
          />
          <path
            d="M30.8154297,16.1704102c0.3408203,0,0.6176758-0.2773438,0.6176758-0.6181641v-1.6396484h-1.2358398v1.6396484
           C30.1972656,15.8989258,30.46875,16.1704102,30.8154297,16.1704102z"
          />
          <path
            d="M32.4331055,15.5522461c0,0.8925781-0.7255859,1.6181641-1.6176758,1.6181641s-1.6181641-0.7255859-1.6181641-1.6181641
           v-1.6396484H15.5825195l0.0102539,27.5986328l1.4042969,1.4042969h15.5830078
           c0.5429688-0.5439453,0.8935547-0.8945313,1.4042969-1.4121094l-0.0102539-27.5908203h-1.5410156V15.5522461z
            M18.7529297,41.3647461c-0.8901367,0-1.6142578-0.7236328-1.6142578-1.6132813l-0.0004883-3.1269531
           c-0.0004883-0.4296875,0.1665039-0.8349609,0.4692383-1.1396484c0.3046875-0.3056641,0.7109375-0.4746094,1.1445313-0.4746094
           c0.8896484,0,1.6137695,0.7236328,1.6137695,1.6132813l0.0009766,3.1269531
           C20.3666992,40.6401367,19.6425781,41.3647461,18.7529297,41.3647461z M24.784668,34.2358398
           c-4.2158203,0-7.6459961-3.4296875-7.6464844-7.6445313c-0.0004883-2.0419922,0.7944336-3.9619141,2.2382813-5.40625
           s3.3637695-2.2402344,5.40625-2.2402344c0.0004883,0,0.0014648,0,0.0019531,0
           c2.0415039,0,3.9604492,0.7949219,5.4042969,2.2382813c1.4443359,1.4433594,2.2397461,3.3632813,2.2402344,5.40625
           C32.4296875,30.8041992,29.0004883,34.2348633,24.784668,34.2358398z"
          />
          <path
            d="M24.784668,19.9448242c-0.0009766,0-0.0009766,0-0.0019531,0c-1.7753906,0-3.4438477,0.6914063-4.6992188,1.9472656
           c-1.2548828,1.2548828-1.9458008,2.9238281-1.9453125,4.6992188c0.0004883,3.6640625,2.9819336,6.6445313,6.6464844,6.6445313
           c3.6640625-0.0009766,6.6450195-2.9824219,6.6445313-6.6464844c0-1.7753906-0.6918945-3.4443359-1.9472656-4.6992188
           C28.2275391,20.6352539,26.559082,19.9448242,24.784668,19.9448242z M29.331543,26.8331299h-0.6504517
           c-0.0507813,0.6605225-0.2438965,1.1685791-0.5894775,1.5245361c-0.34552,0.345459-0.7927246,0.5385742-1.3618164,0.5488281
           v-0.8742676c0.2540894-0.0202637,0.4675293-0.0913086,0.6504517-0.203125
           c0.3353271-0.2032471,0.5182495-0.5386963,0.5284424-0.9959717h-2.4188232
           c-0.2134399,0.7723389-0.416687,1.2906494-0.6098022,1.5651855c-0.3150635,0.4471436-0.7723999,0.6706543-1.3822021,0.6706543
           c-0.8841553,0-1.5142822-0.2845459-1.8903198-0.8536377c-0.2032471-0.3251953-0.3353882-0.7825928-0.4065552-1.3822021h-0.9655151
           v-0.4776611h0.9655151c0.0609741-0.9655762,0.4065552-1.6159668,1.0264893-1.9615479
           c0.3353271-0.1932373,0.7927246-0.2845459,1.3618164-0.2845459v0.8740234
           c-0.4572754,0.0305176-0.7824707,0.1016846-0.9959717,0.2235107c-0.3760376,0.2032471-0.5894775,0.5793457-0.6300659,1.1384277
           h2.713562c0.1320801-0.7114258,0.3658447-1.2398682,0.6809082-1.5855713
           c0.3151245-0.345459,0.762207-0.5183105,1.321228-0.5183105c0.5081787,0,0.9654541,0.1931152,1.3720093,0.559082
           c0.4065552,0.3657227,0.6098022,0.8942871,0.6199341,1.5549316h0.6606445V26.8331299z"
          />
          <path
            d="M46.5649414,19.2426758V7.5727539c0-1.3398438-1.090332-2.4306641-2.4301758-2.4306641H5.4248047
           c-1.3344727,0-2.4199219,1.0908203-2.4199219,2.4306641v11.6699219c0,1.3447266,1.0854492,2.4394531,2.4199219,2.4394531h5.75
           v-4.25H7.8447266c-0.9814453,0-1.7797852-0.7978516-1.7797852-1.7792969v-4.4804688
           c0-0.9814453,0.7983398-1.7802734,1.7797852-1.7802734h33.8803711c0.9814453,0,1.7797852,0.7988281,1.7797852,1.7802734v4.4804688
           c0,0.9814453-0.7983398,1.7792969-1.7797852,1.7792969h-3.3447876l0.0014648,4.25h5.7529907
           C45.4746094,21.6821289,46.5649414,20.5874023,46.5649414,19.2426758z"
          />
          <path
            d="M18.7519531,36.0102539c-0.1655273,0-0.3198242,0.0634766-0.4355469,0.1796875
           c-0.1152344,0.1162109-0.1782227,0.2705078-0.1782227,0.4345703l0.0004883,3.1269531
           c0,0.3378906,0.2753906,0.6132813,0.6142578,0.6132813c0.3383789,0,0.6137695-0.2753906,0.6137695-0.6142578l-0.0009766-3.1269531
           C19.3657227,36.2856445,19.090332,36.0102539,18.7519531,36.0102539z"
          />
          <path
            d="M37.375,16.9321289v-3.0195313h-2.4006958l0.0100708,27.7958984c0,0.1308594-0.0517578,0.2578125-0.144043,0.3515625
           c-0.6416016,0.6503906-1.0151367,1.0244141-1.640625,1.6503906c-0.09375,0.09375-0.2797852,0.2050781-0.4125977,0.2050781
           H16.7900391c-0.1328125,0-0.2597656-0.0527344-0.3535156-0.1464844l-1.6972656-1.6972656
           c-0.09375-0.09375-0.1464844-0.2207031-0.1464844-0.3535156l-0.0100708-27.8056641h-2.40448l0.0102539,29.3808594
           c0,1.6689453,1.3574219,3.0263672,3.0263672,3.0263672l19.1469727-0.0097656c1.6694336,0,3.0273438-1.3574219,3.0273438-3.0263672
           l-0.0073242-21.0678711C37.3809814,22.2041016,37.375,22.1940918,37.375,22.1821289V16.9321289z"
          />
          <path
            d="M26.7399292,25.1459961c-0.3251953,0-0.5792847,0.1015625-0.7723999,0.3251953
           c-0.1829224,0.213501-0.3150635,0.5080566-0.3861694,0.8741455h2.3476563
           c-0.0203247-0.4371338-0.1524658-0.7419434-0.4065552-0.9249268
           C27.2785645,25.2374268,27.0143433,25.1459961,26.7399292,25.1459961z"
          />
          <path
            d="M49.9951172,19.2426758V7.5727539c0-1.3398438-1.090332-2.4306641-2.4301758-2.4306641h-1.0117188
           c0.0010376,0.0009766,0.001709,0.0021973,0.0027466,0.0032959c0.0935059,0.0932617,0.1702881,0.2012939,0.2525635,0.3048096
           c0.0546875,0.0688477,0.1181641,0.130127,0.1676025,0.203125c0.0784302,0.1157227,0.1373901,0.2441406,0.201416,0.3692627
           c0.0371094,0.0725098,0.0843506,0.1387939,0.116333,0.2141113c0.0550537,0.1297607,0.0880127,0.2694092,0.1269531,0.4067383
           c0.0227661,0.0802002,0.0570679,0.1553955,0.0739746,0.237915c0.0458984,0.2233887,0.0701294,0.4545898,0.0701294,0.6914063
           v11.6699219c0,0.2375488-0.024292,0.4694824-0.0702515,0.6937256c-0.0157471,0.0765381-0.0478516,0.1463623-0.0685425,0.2209473
           c-0.039917,0.1439209-0.0751343,0.2897949-0.1326294,0.425415c-0.0378418,0.0893555-0.0925293,0.168335-0.1373901,0.2536621
           c-0.0587769,0.1113281-0.1102295,0.227417-0.1804199,0.3312988c-0.0588379,0.0870361-0.1331787,0.1611328-0.1995239,0.2423096
           c-0.0733032,0.0897217-0.1394653,0.1853027-0.2212524,0.2670898c-0.0015259,0.0015869-0.0026245,0.00354-0.0041504,0.0050049
           h1.0141602C48.9047852,21.6821289,49.9951172,20.5874023,49.9951172,19.2426758z"
          />
          <path
            d="M42.5048828,15.652832v-4.4804688c0-0.4306641-0.3496094-0.7802734-0.7797852-0.7802734H7.8447266
           c-0.4301758,0-0.7797852,0.3496094-0.7797852,0.7802734v4.4804688c0,0.4296875,0.3496094,0.7792969,0.7797852,0.7792969h3.3300781
           v-2.5244141H9.8793945c-0.2763672,0-0.5-0.2236328-0.5-0.5s0.2236328-0.5,0.5-0.5h1.7988281h3.4042969h14.6147461h2.2358398
           h2.5410156h3.4047852h1.809082c0.2763672,0,0.5,0.2236328,0.5,0.5s-0.2236328,0.5-0.5,0.5h-1.3088989l0.0008545,2.5244141
           h3.3451538C42.1552734,16.4321289,42.5048828,16.0825195,42.5048828,15.652832z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WithdrawIcon;
