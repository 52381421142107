export enum EDialogTypes {
  IMPORT_HOLDER_TOKEN = 'IMPORT_HOLDER_TOKEN',
  IMPORT_HOLDER_WALLET = 'IMPORT_HOLDER_WALLET',
}

export enum EWithdrawForms {
  OKX = 'OKX',
  MEXC = 'MEXC',
  GATEIO = 'GATEIO',
  BINANCE = 'BINANCE',
  BYBIT = 'BYBIT',
  BITGET = 'BITGET',
}
