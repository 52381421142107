export const HolderIcon = () => (
  <svg
    width="22px"
    height="14px"
    viewBox="0 0 22 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Rounded" transform="translate(-203.000000, -4103.000000)">
        <g id="Social" transform="translate(100.000000, 4044.000000)">
          <g
            id="-Round-/-Social-/-people"
            transform="translate(102.000000, 54.000000)"
          >
            <g>
              <path
                d="M16,11 C17.66,11 18.99,9.66 18.99,8 C18.99,6.34 17.66,5 16,5 C14.34,5 13,6.34 13,8 C13,9.66 14.34,11 16,11 Z M8,11 C9.66,11 10.99,9.66 10.99,8 C10.99,6.34 9.66,5 8,5 C6.34,5 5,6.34 5,8 C5,9.66 6.34,11 8,11 Z M8,13 C5.67,13 1,14.17 1,16.5 L1,18 C1,18.55 1.45,19 2,19 L14,19 C14.55,19 15,18.55 15,18 L15,16.5 C15,14.17 10.33,13 8,13 Z M16,13 C15.71,13 15.38,13.02 15.03,13.05 C15.05,13.06 15.06,13.08 15.07,13.09 C16.21,13.92 17,15.03 17,16.5 L17,18 C17,18.35 16.93,18.69 16.82,19 L22,19 C22.55,19 23,18.55 23,18 L23,16.5 C23,14.17 18.33,13 16,13 Z"
                id="🔹-Icon-Color"
                fill="#a43399"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
