/* eslint-disable max-lines */
import { DeleteIcon, EyeIcon } from '@/components/icons';
import {
  formatNumber,
  formatTokenAmount,
  lamportToSol,
  mappingSnipStatus,
  toTokenAmount,
} from '@/utils/helper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import NumberInput from '@/components/form/numberInput';
import { InputContainer } from '@/components/form/styled';
import { ShowMoreIcon } from '@/components/icons/ShowMore';
import { ConfirmDeletePopup } from '@/components/token/components/confirmDeletePopup';
import { useAppDispatch } from '@/hooks';
import usePumpHook from '@/hooks/usePumpHook';
import { updateListTraderActive } from '@/stores/slices/pump/pump';
import { Box, Chip, Menu, MenuItem, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AntSwitch, LinkText, Text } from './component';
import { TableType } from './table';
import { FloorRowProps, TokenRowProps } from './types';

export const TopRow = ({ row }: FloorRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_600">{row?.address}</TableCell>
      <TableCell className="width_400">
        {formatNumber(+row?.tokenBalance)}
      </TableCell>
      <TableCell className="width_100">{row?.percentage}</TableCell>
    </TableRow>
  );
};

export const SnipRow = ({ row }: FloorRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell className="width_7">{row.index + 1}</TableCell>
      <TableCell className="width_500">{row?.wallet?.address}</TableCell>
      <TableCell className="width_15">
        {formatNumber(row?.amount ? row?.amount / Math.pow(10, 9) : 0)}
      </TableCell>
      <TableCell className="width_12">
        {mappingSnipStatus(row?.status)}
      </TableCell>
      <TableCell className="word_break">
        {row?.tx ? (
          <a
            href={`https://solscan.io/tx/${row?.tx}`}
            target="_blank"
            rel="noreferrer"
          >
            {row?.tx}
          </a>
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
};

export const PumpfunTokenRow = ({ row, onDelete }: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      {/* <TableCell className="p_8">
        {row?.image ? <img src={row?.image} alt={row?.name} /> : ''}
      </TableCell> */}
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contract_address}</TableCell>
      <TableCell className="width_100">{formatNumber(row?.supply)}</TableCell>
      <TableCell>
        <div className="header_button">
          <button
            className="main_btn multi_color_btn button new_btn danger"
            onClick={() => onDelete && onDelete(row.id)}
          >
            Delete
          </button>
          {/* <LinkText to={`/tokens/${row.id}`}> */}
          <EyeIcon />
          <Text>View</Text>
          {/* </LinkText> */}
        </div>
      </TableCell>
    </TableRow>
  );
};

export const TokenRow = ({
  row,
  onView,
  onDelete,
  onEdit,
  onSelect,
  selected,
}: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      {/* <TableCell className="p_8">
        {row?.image ? <img src={row?.image} alt={row?.name} /> : ''}
      </TableCell> */}
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contract_address}</TableCell>
      <TableCell className="width_100">{formatNumber(row?.supply)}</TableCell>
      <TableCell>
        <div className="header_button">
          <button
            className="main_btn multi_color_btn button new_btn danger"
            onClick={() => onDelete && onDelete(row.id)}
          >
            Delete
          </button>
          <LinkText to={`/tokens/${row.id}`}>
            <EyeIcon />
            <Text>View</Text>
          </LinkText>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const HolderTokenRow = ({ row }: TokenRowProps) => {
  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contractAddress}</TableCell>
      <TableCell className="width_100">
        {formatTokenAmount(row?.supply ?? 0, row?.decimals ?? 0, false)}
      </TableCell>
      <TableCell>
        <div className="header_button">
          <LinkText to={`/holders/tokens/${row.id}`}>
            <EyeIcon />
            <Text>View</Text>
          </LinkText>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const HolderTokenDetailRow = ({ row }: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.owner}</TableCell>
      <TableCell className="width_200">{row?.solBalance}</TableCell>
      <TableCell className="width_200">
        {formatTokenAmount(row?.amount, row?.decimals)}
      </TableCell>
      <TableCell className="width_50">{`${row?.percentage ?? 0}%`}</TableCell>
    </TableRow>
  );
};

export const BootSellTxRow = ({ row }: TokenRowProps) => {
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
      <TableCell sx={{ width: '55%' }}>{row?.contract_address}</TableCell>
      <TableCell sx={{ width: '15%' }}>
        <div className="header_button">
          {' '}
          <Box className="main_btn multi_color_btn button new_btn danger">
            <LinkText to={`/boot_sell_tx/${row.id}`}>
              <span>boot sell tx</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const BootBuyTxRow = ({ row, type }: TokenRowProps) => {
  const handleClickBootBuyTx = () => {
    if (type === TableType.BOOT_TX) {
      localStorage.setItem('rootPath', 'Boot Buy/Sell TX');
    } else {
      localStorage.setItem('rootPath', 'Pump Boot Buy/Sell TX');
    }
  };
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      <TableCell className="width_30">{row.symbol}</TableCell>
      <TableCell className="width_200">{row?.contract_address}</TableCell>
      <TableCell className="last">
        <div className="header_button">
          {' '}
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText
              to={
                type === TableType.BOOT_TX
                  ? `/raydium/boot_tx/buy/${row.id}`
                  : `/pumpfun/boot_tx/buy/${row.id}`
              }
            >
              <span>boot buy tx</span>
            </LinkText>
          </Box>
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText
              to={
                type === TableType.BOOT_TX
                  ? `/raydium/boot_tx/sell/${row.id}`
                  : `/pumpfun/boot_tx/sell/${row.id}`
              }
            >
              <span>boot sell tx</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const BootVolumeTxRow = ({ row, type }: TokenRowProps) => {
  const handleClickBootBuyTx = () => {
    if (type === TableType.BOOT_VOLUME) {
      localStorage.setItem('rootPath', 'Boot Volume TX');
    } else {
      localStorage.setItem('rootPath', 'Pump Boot Volume TX');
    }
  };
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contract_address}</TableCell>
      <TableCell className="last">
        <div className="header_button">
          {' '}
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText
              to={
                type === TableType.BOOT_VOLUME
                  ? `/raydium/boot_tx/volume/${row.id}`
                  : `/pumpfun/boot_tx/volume/${row.id}`
              }
            >
              <span>boot volume tx</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const WalletSellRow = ({ row, control }) => {
  const { t } = useTranslation();
  const { requestDeleteBootTxWallet } = usePumpHook();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteWallet = () => {
    setOpenPopup(true);
  };

  const handleConfirmDelete = () => {
    requestDeleteBootTxWallet({ walletIds: [row.id] });
    handleClose();
  };

  return (
    <>
      <ConfirmDeletePopup
        open={openPopup}
        onSubmit={handleConfirmDelete}
        onClose={() => setOpenPopup(false)}
      />
      <TableRow
        key={row.index}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          width: '100%',
        }}
      >
        <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
        <TableCell sx={{ width: '55%' }}>{row?.address}</TableCell>
        <TableCell sx={{ width: '10%' }}>
          {lamportToSol(row?.solBalance)}
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <div className="header_button">
            <Controller
              control={control}
              name={`volume${row.id}`}
              rules={{
                required: t('message.error.requiredField'),
              }}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <NumberInput
                    label={''}
                    // error={errors?.ownerPrivateKey?.message}
                    value={value}
                    onChange={onChange}
                    max={0.01}
                    noBottom
                  />
                );
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ width: '10%' }}>
          <div>
            <Box
              onClick={handleClick}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: '#2C2D30',
                width: '40px',
                height: '40px',
              }}
            >
              <ShowMoreIcon />
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiMenuItem-root ': {
                  color: '#fff',
                  background: '#2c2d30',
                  borderBottom: 'none',
                },
                '& .MuiMenuItem-root:hover ': {
                  background: '#3f3f3f',
                },
              }}
            >
              <MenuItem
                onClick={handleDeleteWallet}
                sx={{
                  color: '#fff',
                  display: 'flex',
                  gap: '4px',
                  fontSize: '14px',
                }}
              >
                <DeleteIcon />
                Remove wallet
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export const ListTransactionRow = ({ row }) => {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <StyledTableRow
      key={row.index}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        width: '100%',
        background: '#fff',
      }}
    >
      <StyledTableCell sx={{ width: '100%' }}>{row?.signature}</StyledTableCell>
    </StyledTableRow>
  );
};

export const PumpfunTraderDetailRow = ({ row, id }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const tokenDetail = JSON.parse(localStorage.getItem('token_detail') || '{}');
  const [active, setActive] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPopup, setOpenPopup] = useState({
    transferSol: false,
    transferToken: false,
    buy: false,
    walletInfo: false,
    confirmDelete: false,
  });

  const {
    requestActiveWallet,
    requestUnActiveWallet,
    requestDeletePumpWallet,
    listPumpToken,
    requestGetListPumpToken,
  } = usePumpHook();
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWalletInfoSubmit = () => {};

  const handleDeleteWallet = () => {
    setOpenPopup({ ...openPopup, confirmDelete: true });
  };

  const handleConfirmDelete = () => {
    requestDeletePumpWallet({ walletIds: [row.id] });
    console.log('delete1');
    handleClose();
    console.log('delete2');
  };

  /** fetch data based on query string */
  // useEffect(() => {
  //   setTimeout(() => {
  //     requestGetListPumpToken();
  //   }, 0);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname, location.search]);

  useEffect(() => {
    setActive(row?.isActive);
  }, [row?.isActive]);

  return (
    <>
      <TableRow
        key={row.index}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          width: '100%',
        }}
      >
        <TableCell sx={{ width: '5%' }}>{row.index + 1}</TableCell>
        <TableCell sx={{ width: '25%' }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
            onClick={() => setOpenPopup({ ...openPopup, walletInfo: true })}
          >
            <Box>{row?.walletName}</Box>
            <Box>{row?.address}</Box>
          </Box>
        </TableCell>
        <TableCell sx={{ width: '13%' }}>
          {lamportToSol(row?.solBalance)}
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          {parseFloat(
            toTokenAmount(row?.tokenBalance, tokenDetail.decimal),
          ).toLocaleString('en-US')}
        </TableCell>
        <TableCell
          sx={{
            width: '12%',
          }}
        >
          {!isNaN(Number(row.profit)) && (
            <Chip
              label={`${row?.profit}%`}
              color={
                row?.profit > 80
                  ? 'success'
                  : row?.profit > 50
                  ? 'warning'
                  : 'error'
              }
            />
          )}
        </TableCell>

        <TableCell
          sx={{
            width: '10%',
            '& div': {
              marginBottom: '0px',
            },
          }}
        >
          <InputContainer className="mt1 ">
            <AntSwitch
              checked={active}
              onChange={() => {
                dispatch({
                  type: updateListTraderActive.type,
                  payload: {
                    active: !active,
                    data: row,
                  },
                });
                setActive(!active);
                const payload = { walletIds: [row.id] };
                if (!active) {
                  requestActiveWallet(payload);
                } else {
                  requestUnActiveWallet(payload);
                }
              }}
            />
          </InputContainer>
        </TableCell>
        <TableCell sx={{ width: '10%' }}>
          <div>
            <Box
              onClick={handleClick}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: '#2C2D30',
                width: '40px',
                height: '40px',
              }}
            >
              <ShowMoreIcon />
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiMenuItem-root ': {
                  color: '#fff',
                  background: '#2c2d30',
                  borderBottom: 'none',
                },
                '& .MuiMenuItem-root:hover ': {
                  background: '#3f3f3f',
                },
              }}
            >
              <MenuItem
                onClick={handleDeleteWallet}
                sx={{
                  color: '#fff',
                  display: 'flex',
                  gap: '4px',
                  fontSize: '14px',
                }}
              >
                <DeleteIcon />
                Remove wallet
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
      </TableRow>
      <ConfirmDeletePopup
        open={openPopup.confirmDelete}
        onSubmit={handleConfirmDelete}
        onClose={() => setOpenPopup({ ...openPopup, confirmDelete: false })}
      />
      {/* <WalletInfoPopup
        open={openPopup.walletInfo}
        onSubmit={handleWalletInfoSubmit}
        onClose={() => {
          setOpenPopup({ ...openPopup, walletInfo: false });
        }}
      /> */}
    </>
  );
};

export const PumpfunTraderRow = ({ row }: TokenRowProps) => {
  const handleClickBootBuyTx = () => {
    localStorage.setItem('rootPath', `Trader`);
    localStorage.setItem('CA', `${row.contractAddress}`);
    localStorage.setItem('rootPathTrader', `/trader/${row.id}`);
  };
  return (
    <TableRow
      key={row.index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{row.index + 1}</TableCell>
      <TableCell className="width_50">{row?.name}</TableCell>
      <TableCell className="width_30">{row?.symbol}</TableCell>
      <TableCell className="width_200">{row?.contractAddress}</TableCell>
      <TableCell className="last">
        <div className="header_button">
          {' '}
          <Box
            className="main_btn multi_color_btn button new_btn danger"
            onClick={handleClickBootBuyTx}
          >
            <LinkText to={`/trader/${row.id}`}>
              <span>trade</span>
            </LinkText>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};
