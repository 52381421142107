import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import Input from '@/components/form/input';
import { InputContainer } from '@/components/form/styled';
import {
  getSupplyByMintToken,
  getWalletBalanceToken,
  toTokenAmount,
} from '@/utils/helper';
import { useTranslation } from 'react-i18next';
import { debounce, isEmpty, isNumber } from 'lodash';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { burnSolRequest } from '@/stores/slices/token/token';
import CustomButton, { CustomButtonType } from '@/components/common/Button';
import CommonLoading from '@/components/common/CommonLoading';
import { RequestStatus } from '@/constants/API';
import BurnSolSlider from '@/components/form/BurnSolSlider';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import useConnectWallet from '@/hooks/useConnectWallet';
import { NETWORK } from '@/constants';

if (!NETWORK) {
  throw new Error('REACT_APP_RFC_URL environment variable is not set');
}

const connection = new Connection(NETWORK, {
  commitment: 'confirmed',
});

const initBurnSOLValues = {
  contractAddress: '',
  ownerAddress: '',
  percentage: 100,
  quantity: 0,
};

interface IBurnSolForm {
  contractAddress: string;
  ownerAddress: string;
  percentage: string | number;
  quantity: string | number;
}

interface IBurnSolFormProps {}

const BurnSolForm: React.FC<IBurnSolFormProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [retrieveAmount, setRetrieveAmount] = useState<{
    tokenAmount: number | string;
    supply?: string;
    decimals?: number;
  }>({
    tokenAmount: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { burnSol } = useAppSelector(state => state.token);
  const { publicKey, signAndSendTransactionHandler, handleDisconnect } =
    useConnectWallet();
  const {
    control,
    clearErrors,
    handleSubmit,
    setError,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IBurnSolForm>({
    mode: 'all',
    defaultValues: initBurnSOLValues,
  });

  const contractAddressValidator = (
    contractAddress: string,
  ): boolean | string => {
    if (isEmpty(publicKey)) {
      return 'You need to connect to Phantom first!';
    }
    if (isEmpty(contractAddress)) {
      return 'This field is required';
    }

    return true;
  };

  const amountValidator = (amount: number | string) => {
    if (isEmpty(publicKey)) {
      return 'You need to connect to Phantom first!';
    }
    if (!isNumber(+amount)) {
      return 'Invalid value';
    }

    if (+amount <= 0) {
      return 'This field require to be greater than 0';
    }

    if (+amount > +retrieveAmount.tokenAmount) {
      return 'This field require to be smaller than token amount';
    }

    return true;
  };

  const retrieveTokenAmountDebounce = debounce(
    async (contactAddress: string) => {
      if (isEmpty(publicKey) || isEmpty(contactAddress)) return;
      try {
        setIsLoading(true);
        const walletBalance = await getWalletBalanceToken(
          connection,
          publicKey,
          contactAddress,
        );
        const supplyInfo = await getSupplyByMintToken(
          connection,
          contactAddress,
        );
        const ownerTokenAmount = parseInt(
          toTokenAmount(walletBalance, supplyInfo.decimals),
        );

        setRetrieveAmount({
          ...retrieveAmount,
          tokenAmount: ownerTokenAmount,
          supply: supplyInfo.supply,
          decimals: supplyInfo.decimals,
        });
        if (isNumber(ownerTokenAmount) && ownerTokenAmount <= 0) {
          setValue('percentage', 0);
        }
        setValue('quantity', ownerTokenAmount);
      } catch (error) {
        setError('contractAddress', {
          message: 'Invalid contract address!',
        });
      } finally {
        setIsLoading(false);
      }
    },
    800,
  );

  const setPercentageHandler = (quantityInput: string): void => {
    const result = (
      (Number(quantityInput) / Number(retrieveAmount.tokenAmount)) *
      100
    ).toFixed(0);

    setValue('percentage', parseInt(result));
  };

  const setQuantityHandler = (inputValue: string): void => {
    const result: string = (
      (Number(inputValue) * Number(retrieveAmount.tokenAmount)) /
      100
    ).toFixed(2);

    setValue('quantity', parseInt(result));
  };

  const submitHandler = ({ percentage, ...data }: IBurnSolForm) => {
    dispatch(
      burnSolRequest({
        formData: {
          ...data,
          quantity: +data.quantity * Math.pow(10, retrieveAmount.decimals || 1),
        },
        messages: {
          success: 'Burn SOL successfully!',
          failed: 'Something went wrong. Burn SOL unsuccessfully!',
        },
        callback: () => {
          reset({ ...initBurnSOLValues, ownerAddress: publicKey?.toBase58() });
        },
        sendTransactionHandler: async transaction => {
          await signAndSendTransactionHandler(transaction);
        },
      }),
    );
  };

  useEffect(() => {
    if (!isEmpty(publicKey)) {
      setValue('ownerAddress', publicKey.toBase58());
      clearErrors(['contractAddress', 'percentage', 'quantity']);
    }
  }, [publicKey]);

  useEffect(() => {
    return () => {
      handleDisconnect();
    };
  }, []);

  return (
    <Box component="form">
      <InputContainer className="single">
        <Controller
          control={control}
          name="contractAddress"
          rules={{
            validate: contractAddressValidator,
          }}
          render={({ field: { value, onChange } }) => (
            <Input
              label={t('placeholder.placeholder.enterParam', {
                param: t('label.contract_address'),
              })}
              labelName={t('label.contract_address')}
              isRequired
              error={errors.contractAddress?.message}
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e);
                retrieveTokenAmountDebounce(e.target.value);
              }}
            />
          )}
        />
      </InputContainer>
      <InputContainer className="single">
        <Controller
          control={control}
          name="quantity"
          rules={{
            validate: amountValidator,
          }}
          render={({ field: { value, onChange } }) => (
            <Input
              label={t('placeholder.placeholder.enterParam', {
                param: t('label.token_amount'),
              })}
              labelName={t('label.token_amount')}
              isRequired
              disabled={isEmpty(publicKey) || +retrieveAmount.tokenAmount <= 0}
              error={errors.quantity?.message}
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPercentageHandler(e.target.value);
                onChange(e);
                trigger('percentage');
              }}
            />
          )}
        />
      </InputContainer>
      <InputContainer>
        <Controller
          control={control}
          name="percentage"
          rules={{
            min: {
              value: 0.0001,
              message: 'This field require to be greater than 0',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <BurnSolSlider
              isRequired
              isDisabled={
                isEmpty(publicKey) || +retrieveAmount.tokenAmount <= 0
              }
              label={t('label.percentage')}
              value={value}
              error={errors.percentage?.message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setQuantityHandler(e.target.value);
                onChange(e);
                trigger('quantity');
              }}
            />
          )}
        />
      </InputContainer>
      <Stack direction="row" justifyContent="center">
        <CustomButton
          type="submit"
          buttonType={CustomButtonType.NO_ICON}
          title="Burn"
          className="multi_color_btn main_btn"
          onClick={handleSubmit(submitHandler)}
          disabled={
            isEmpty(publicKey) ||
            (isNumber(+retrieveAmount.tokenAmount) &&
              +retrieveAmount.tokenAmount <= 0)
          }
        />
      </Stack>
      <CommonLoading
        show={burnSol.status === RequestStatus.REQUESTING || isLoading}
      />
    </Box>
  );
};

export default BurnSolForm;
