import { Slider, Stack } from '@mui/material';
import * as React from 'react';

import { InputType } from './customSelect';
import { InputName, InputRequired } from './inputName';
import { ErrorMessage, InputContainer, SliderWrapper } from './styled';

interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string | number;
  label?: string;
  placeholder?: string;
  error?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  inputType?: number;
  width?: number;
  fromType?: InputType;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const SliderItem = ({
  label,
  value,
  error,
  isRequired = false,
  isDisabled = true,
  inputType = InputType.POP_UP,
  onChange,
}: SliderProps) => {
  const [val, setVal] = React.useState<number>(value ? +value : 100);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setVal(newValue as number);
    const triggerEvent = {
      target: {
        value: newValue as unknown as string,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange && onChange(triggerEvent);
  };

  React.useEffect(() => {
    setVal(+value);
  }, [value]);

  return (
    <InputContainer>
      {label && (
        <InputName>
          {label}
          <InputRequired>{isRequired && '*'}</InputRequired>
        </InputName>
      )}

      <SliderWrapper fromType={inputType}>
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between">
            <span>0%</span>
            <span>100%</span>
          </Stack>
          <Slider
            disabled={isDisabled}
            value={Number(val)}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
          />
        </Stack>
      </SliderWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  );
};

export default SliderItem;
