import { Title } from '@/components/login';
import { CommonContainer } from '@/components/layouts/content';
import WithdrawForm from '@/components/token/components/WithdrawForm';
import { Box } from '@mui/material';
import { EWithdrawForms } from '@/enums/holder';

const BinanceWithdrawPage = () => {
  return (
    <CommonContainer className="top">
      <Title>Binance Withdraw</Title>
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 700,
        }}
      >
        <WithdrawForm mode={EWithdrawForms.BINANCE} />
      </Box>
    </CommonContainer>
  );
};

export default BinanceWithdrawPage;
