/* eslint-disable max-lines */
import { PaginationProps } from '@/components/common/pagination';
import { AntSwitch, Text } from '@/components/layouts/table/component';
import { useAppDispatch } from '@/hooks';
import {
  activeWalletRequest,
  unActiveWalletRequest,
} from '@/stores/slices/pump/pump';
import { getTotal, lamportToSol, toTokenAmount } from '@/utils/helper';
import { Stack } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Big from 'big.js';

export enum TableType {
  VENUE = 'VENUE',
  PUMPFUN_TOKEN = 'PUMPFUN_TOKEN',
  TOP = 'TOP',
  DETAIL = 'DETAIL',
  BOOT_TX = 'BOOT_TX',
  BOOT_VOLUME = 'BOOT_VOLUME_TX',
  BOOT_BUY_SELL_TX = 'BOOT_TX_BUY_SELL',
  PUMP_BOOT_TX = 'PUMP_BOOT_TX',
  PUMP_BOOT_VOLUME = 'PUMP_BOOT_VOLUME',
  PUMP_BOOT_BUY_SELL_TX = 'PUMP_BOOT_BUY_SELL_TX',
  LIST_TRANSACTION = 'LIST_TRANSACTION',
  PUMPFUN_TRADER_DETAIL = 'PUMPFUN_TRADER_DETAIL',
  PUMPFUN_TRADER_LIST = 'PUMPFUN_TRADER_LIST',
  HOLDER_TOKENS = 'HOLDER_TOKENS',
  HOLDER_TOKEN_DETAIL = 'HOLDER_TOKEN_DETAIL',
}

export interface CommonTableProps {
  control?: any;
  type: TableType;
  data: Record<string, any>[];
  pagination?: PaginationProps;
  typeBoot?: string;
  onDelete?: (id: number) => void;
  onAction?: () => void;
  onReload?: () => void;
  idToken?: number | string;
}

export interface TagProps {
  name: string;
  id: number;
}

export interface ICheckHeader {
  indeterminate?: boolean;
  onChange?: (e: any) => void;
}

export interface HeaderProps {
  actionHeader?: () => void;
  holders?: any[];
}

export const TokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        {/* <TopTableHeader>{t('Image')}</TopTableHeader> */}
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">CA</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const HolderTokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">Address</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const HolderTokenDetailHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top">#</TableCell>
        <TableCell>Address</TableCell>
        <TableCell className="width_200">Sol balance</TableCell>
        <TableCell className="width_200">Token balance</TableCell>
        <TableCell className="width_100">%</TableCell>
        {/* <TableCell className="last">{t('Action')}</TableCell> */}
      </TableRow>
    </TableHead>
  );
};

export const TopHeader = ({ actionHeader, holders }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center">#</TableCell>
        <TableCell className="width_600">{t('Address')}</TableCell>
        <TableCell className="width_200">{t('Quantity')}</TableCell>
        <TableCell className="width_100">
          {t('Percentage')}{' '}
          <Text>
            Total: <Text className="bold">{holders && getTotal(holders)}</Text>%
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PumpfunTokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        {/* <TopTableHeader>{t('Image')}</TopTableHeader> */}
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">CA</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const SnipHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center no">#</TableCell>
        <TableCell className="width_5">{t('Address')}</TableCell>
        <TableCell className="width_2">{t('Amount')}</TableCell>
        <TableCell className="width_1">{t('Status')} </TableCell>
        <TableCell className="">{t('TX')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const BootTxHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top">#</TableCell>
        <TableCell className="width_50">Token Name</TableCell>
        <TableCell className="width_30">Symbol</TableCell>
        <TableCell className="width_200">Contract Address</TableCell>
        <TableCell className="last">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const BootTxBuySellHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>#</TableCell>
        <TableCell sx={{ width: '55%' }}>Wallet</TableCell>
        <TableCell sx={{ width: '10%' }}>Balance</TableCell>
        <TableCell sx={{ width: '20%' }}>Volume</TableCell>
        <TableCell sx={{ width: '10%' }}>Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TransactionHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '100%' }}>Transaction</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PumpfunTraderHeader = (data: any) => {
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  const [isAllActive, setIsAllActive] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const infoToken = JSON.parse(localStorage.getItem('token_detail') ?? '{}');
  const [totalTokenBalance, setTotalTokenBalance] = useState(0);
  const [totalSol, setTotalSol] = useState(0);

  const calculatorPercent = value => {
    if (!totalTokenBalance) return;
    if (!infoToken.decimal) return;
    const result = Big(totalTokenBalance)
      .div(Big(infoToken.supply))
      .mul(100)
      .toFixed(2);
    return result;
  };

  const handleReturnToTokenAmount = () => {
    if (totalTokenBalance) {
      const toTokenAmountParse = parseFloat(
        toTokenAmount(totalTokenBalance, infoToken?.decimal),
      ).toLocaleString('en-US');
      const percent = calculatorPercent(totalTokenBalance);
      return `${toTokenAmountParse} - (${percent})%`;
    }
    return '0%';
  };
  const changeWalletStatusesHandler = () => {
    const params: {
      activeIds: number[];
      inActiveIds: number[];
    } = data.data?.reduce(
      (acc, wallet) => {
        if (wallet.isActive) {
          acc.inActiveIds.push(wallet.id);
        } else {
          acc.activeIds.push(wallet.id);
        }
        return acc;
      },
      { activeIds: [], inActiveIds: [] },
    );
    if (isAllActive) {
      dispatch(
        unActiveWalletRequest({
          walletIds: params.inActiveIds,
        }),
      );
    } else {
      dispatch(
        activeWalletRequest({
          walletIds: params.activeIds,
        }),
      );
    }
    setIsAllActive(!isAllActive);
  };

  useEffect(() => {
    const total: { sol: number; token: number } = data?.data?.reduce(
      (acc, wallet) => {
        acc.token += BigInt(wallet.tokenBalance);
        acc.sol += Number(lamportToSol(wallet.solBalance));
        return acc;
      },
      {
        sol: 0,
        token: BigInt(0),
      },
    );

    setTotalSol(total.sol);
    setTotalTokenBalance(total.token);
  }, [data]);

  useEffect(() => {
    const isAllWalletActive = data.data.every(wallet => wallet.isActive);
    setIsAllActive(isAllWalletActive);
  }, [data.data]);

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>No</TableCell>
        <TableCell sx={{ width: '25%' }}>Wallet</TableCell>
        <TableCell sx={{ width: '13%' }}>
          <div>SOL Balance</div>
          <div>{totalSol}</div>
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <div>Token</div>
          <div>{handleReturnToTokenAmount()}</div>
        </TableCell>
        <TableCell sx={{ width: '12%' }}>Profit</TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <span>Active all:</span>
            {!!data.data?.length && (
              <AntSwitch
                checked={isAllActive}
                onChange={changeWalletStatusesHandler}
              />
            )}
          </Stack>
        </TableCell>
        <TableCell sx={{ width: '10%' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};
